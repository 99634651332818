// TermsOfService.js
import React from 'react';
import ReactModal from 'react-modal';
import styles from '../components/styles/PrivacyPolicy.module.css';
import '../components/styles/Pricing.css'; // Import Pricing.css for close button styling

const TermsOfService = ({ isOpen, onRequestClose }) => {
  return (
    <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Terms of Service"
    className="pricingModal" // Reuse Pricing modal styles or create specific styles
    overlayClassName="pricingModalOverlay"
    closeTimeoutMS={300}
    ariaHideApp={false} // Ensure accessibility settings as per your app's requirements
  >
    <div className={styles.privacyPolicyContainer}>
    <button onClick={onRequestClose} className="closeModalButton" aria-label="Close Pricing Modal">
      &times;
      </button>
      <h1>Terms of Service</h1>
      <p className={styles.lastRevised}>Last Revised on September 26, 2024</p>

      <p>
        Please take the time to read these Terms of Service (these <strong>"Terms"</strong>) for the{' '}
        <a href="https://onlyjpegs.com">onlyjpegs.com</a> website (the <strong>"Website"</strong>)
        operated by OnlyJPEGs (<strong>"Company"</strong>, <strong>"we"</strong>, or{' '}
        <strong>"us"</strong>). The Website and any related mobile applications (the{' '}
        <strong>"App"</strong>), along with the content, tools, features, and functionality offered
        on or through our Website and App, are collectively referred to as the{' '}
        <strong>"Services"</strong>.
      </p>

      <p>
        These Terms govern your access to and use of the Services. Please read these Terms
        carefully, as they include important information about your legal rights. By accessing
        and/or using the Services, you are agreeing to these Terms. If you do not understand or
        agree to these Terms, please do not use the Services.
      </p>

      <p>
        For purposes of these Terms, <strong>"you"</strong> and <strong>"your"</strong> means you as
        the user of the Services. If you use the Services on behalf of a company or other entity,
        then <strong>"you"</strong> includes you and that entity, and you represent and warrant that
        (a) you are an authorized representative of the entity with the authority to bind the entity
        to these Terms, and (b) you agree to these Terms on the entity's behalf.
      </p>

      <p className={styles.importantNotice}>
        <strong>
          Section 9 contains an arbitration clause and class action waiver. By agreeing to these
          Terms, you agree (a) to resolve all disputes (with limited exception) related to the
          Company's Services and/or products through binding individual arbitration, which means
          that you waive any right to have those disputes decided by a judge or jury, and (b) to
          waive your right to participate in class actions, class arbitrations, or representative
          actions, as set forth below. You have the right to opt out of the arbitration clause and
          the class action waiver as explained in Section 9.
        </strong>
      </p>

      <hr />

      <hr />

      <h2>1. Overview of Services</h2>

      <h3>1.1 The Services</h3>
      <p>
        The Services allow you to submit prompts, directions, and/or other content (collectively,{' '}
        <strong>"Input Content"</strong>) to generate images using artificial intelligence
        technology. Based on your Input Content, the Services generate images or other visual
        content (collectively, <strong>"Output"</strong>). You acknowledge and agree that we may use
        your Input Content and Output to train or otherwise improve our Services, including our
        artificial intelligence and machine learning models.
      </p>

      <h3>1.2 Use of Output</h3>
      <p>
        The Services may allow you to download copies of your Output and, in some cases, view or
        download Output generated by other users. You may only use another user's Output for your
        personal, non-commercial purposes. You agree not to reproduce, transmit, display, publish,
        or otherwise distribute or make available another user's Output, or any portion thereof, on
        any platform or medium without our prior written consent or the prior written consent of the
        user who created that Output.
      </p>

      <h3>1.3 Your Input Content</h3>
      <p>
        By submitting any Input Content through the Services, you represent and warrant that you
        have, or have obtained, all rights, licenses, consents, permissions, power, and/or authority
        necessary to submit and use (and allow us to use) such Input Content in connection with the
        Services, including for the purpose of generating Output. You represent and warrant that
        your submission of Input Content in connection with your use of the Services, including to
        generate Output, will not violate any law or any third party's rights or terms and
        conditions associated with such Input Content. No additional licenses, permissions,
        consents, or authorizations must be obtained from or payments made to any other person or
        entity by us (or any third party deriving any rights or obligations from us) arising out of
        or related to our use of Input Content, including to create Output and/or train, develop,
        fine-tune, or otherwise improve the Services.
      </p>

      <h3>1.4 Prohibited Content</h3>
      <p>
        You may not direct the Services to generate any Output that is unlawful, defamatory,
        infringing, obscene, excessively violent, pornographic, invasive of privacy or publicity
        rights, harassing, threatening, abusive, hateful, cruel, deceptive, or otherwise
        objectionable. You acknowledge and agree that the Company may, but has no obligation to,
        incorporate content filters or other similar measures in the Services designed to prevent
        certain Input Content from generating certain Output, such as content that is unlawful,
        infringing, or otherwise objectionable.
      </p>

      <hr />

      <h2>2. Who May Use the Services</h2>
      <p>
        You must be at least 13 years of age to use the Services. Minors under the age of majority
        in their jurisdiction but who are at least 13 years old are only permitted to use the
        Services if their parent or guardian accepts these Terms on their behalf prior to use of the
        Services. Children under the age of 13 are not permitted to use the Services. By using the
        Services, you represent and warrant that you meet these requirements.
      </p>

      <hr />

      <h2>3. User Accounts, Subscriptions, and Free Trials</h2>

      <h3>3.1 Creating and Safeguarding Your Account</h3>
      <p>
        To use the Services, you need to create an account or link another account, such as your
        Google account (<strong>"Account"</strong>). You agree to provide us with accurate, complete,
        and updated information for your Account. You can access, edit, and update your Account by
        going to your user profile page on the Website. You are solely responsible for any activity
        on your Account and for maintaining the confidentiality and security of your password. We
        are not liable for any acts or omissions by you in connection with your Account. You must
        immediately notify us at <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a> if you
        know or have any reason to suspect that your Account or password has been stolen,
        misappropriated, or otherwise compromised, or in case of any actual or suspected
        unauthorized use of your Account. You agree not to create any Account if we have previously
        removed your Account or banned you from any of our Services unless we provide written
        consent otherwise.
      </p>

      <h3>3.2 Subscription Payment</h3>
      <p>
        If you buy or subscribe to any of our paid Services, you agree to pay us the applicable fees
        and taxes in U.S. Dollars. Failure to pay these fees and taxes will result in the
        termination of your access to the paid Services. You agree that (a) if you purchase a
        recurring subscription to any of the Services, we may store and continue billing your
        payment method (e.g., credit card) to avoid interruption of such Services, and (b) we may
        calculate taxes payable by you based on the billing information you provide at the time of
        purchase. We reserve the right to change our subscription plans or adjust pricing for the
        Services at any time in our sole discretion. Except as otherwise provided in these Terms,
        any price changes or changes to your subscription plan will take effect following reasonable
        notice to you. All subscriptions are payable in accordance with payment terms in effect at
        the time the subscription becomes payable. Payment can be made by credit card, debit card,
        or other means we may make available. Subscriptions will not be processed until payment has
        been received in full, and any holds on your account by any payment processor are solely
        your responsibility. You acknowledge and agree that all information you provide regarding a
        purchase, including, without limitation, credit card or other payment information, is
        accurate, current, and complete. You represent and warrant that you have the legal right to
        use the payment method you provide to us or our payment processor.
      </p>

      <h3>3.3 Subscription Renewals and Cancellations</h3>
      <p>
        You agree that if you purchase a subscription, your subscription will automatically renew at
        the subscription period frequency referenced on your subscription page (or if not
        designated, then monthly) and at the then-current rates. Your payment method will
        automatically be charged at the start of each new subscription period for the fees and taxes
        applicable to that period. To avoid future subscription charges, you must cancel your
        subscription at least 7 days before the subscription period renewal date by either canceling
        your subscription in the account settings page of the Website or by emailing us at{' '}
        <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a> to inform us that you want to
        cancel your subscription.
      </p>

      <h3>3.4 No Subscription Refunds</h3>
      <p>
        Except as expressly set forth in these Terms, payments for any subscriptions to the Services
        are nonrefundable, and there are no credits for partially used periods. Following any
        cancellation by you, however, you will continue to have access to the paid Services through
        the end of the subscription period for which payment has already been made.
      </p>

      <h3>3.5 Free Trials</h3>
      <p>
        You can sign up for a trial Account for the paid portion of the Services. Your trial period
        starts on the day you create the trial Account and lasts for the duration indicated on your
        free trial confirmation email (or if not specified, then 7 days). If you are on a trial, you
        may cancel at any time until the last day of your trial by following the cancellation
        procedures outlined in Section 3.3 above. If you do not cancel your trial Account at the end
        of your free trial period, and we have notified you that your Account will be converted to a
        paid subscription at the end of the free trial period, you authorize us to charge your
        payment method for continued use of the paid Services. You may then cancel your subscription
        in accordance with Section 3.3 of these Terms. If you cancel your trial Account or decide
        not to purchase a paid version of the Services at the end of your trial period, your content
        or data associated with your trial Account will no longer be available to you, and the
        Company may delete or remove any such content or data.
      </p>

      <h3>3.6 Promotional Codes</h3>
      <p>
        We may offer certain promotional codes, referral codes, discount codes, coupon codes, or
        similar offers (<strong>"Promotional Codes"</strong>) that may be redeemed for discounts on
        subscriptions to our Services or other features or benefits related to the Services, subject
        to any additional terms that the Company establishes. You agree that Promotional Codes:
      </p>
      <ul>
        <li>Must be used in a lawful manner.</li>
        <li>Must be used for the intended audience and purpose.</li>
        <li>
          May not be duplicated, sold, or transferred in any manner or made available by you to the
          general public (whether posted to a public forum, coupon collecting service, or otherwise)
          unless expressly permitted by the Company.
        </li>
        <li>
          May be disabled or have additional conditions applied to them by the Company at any time
          for any reason without liability to the Company.
        </li>
        <li>
          May only be used pursuant to the specific terms that the Company establishes for such
          Promotional Code.
        </li>
        <li>Are not valid for cash or other credits or points.</li>
        <li>May expire prior to your use.</li>
      </ul>

      <hr />

      <h2>4. Location of Our Privacy Policy</h2>
      <h3>4.1 Privacy Policy</h3>
      <p>
        Our Privacy Policy describes how we handle the information you provide to us when you use the Services.
        For an explanation of our privacy practices, please visit our Privacy Policy located at{' '}
        <a href="https://onlyjpegs.com/privacy-policy">https://onlyjpegs.com/privacy-policy</a>.
      </p>

      <h2>5. Rights We Grant You</h2>
      <h3>5.1 Right to Use Services</h3>
      <p>
        We hereby permit you to use the Services, provided that you comply with these Terms in connection with all such use.
        If any software, content or other materials owned or controlled by us are distributed to you as part of your use of the Services,
        we hereby grant you, a personal, non-assignable, non-sublicensable, non-transferrable, and non-exclusive right and license
        to access and display such software, content and materials provided to you as part of the Services (and right to download a
        single copy of the App onto your applicable equipment or device), in each case for the sole purpose of enabling you to use
        the Services as permitted by these Terms. Your access and use of the Services may be interrupted from time to time for any of
        several reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of
        the Services or other actions that Company, in its sole discretion, may elect to take.
      </p>

      <h3>5.2 Restrictions On Your Use of the Services</h3>
      <p>
        You may not do any of the following in connection with your use of the Services, unless applicable laws or
        regulations prohibit these restrictions or you have our written permission to do so:
      </p>
      <ul>
        <li>
          <strong>Download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, or offer for sale any proprietary software owned by the Company and deployed for the Services</strong>, except for temporary files that are automatically cached by your web browser for display purposes, or as otherwise expressly permitted in these Terms;
        </li>
        <li>
          <strong>Impersonate somebody using the Services without their authorization</strong>, including by providing Input Content that you do not have authority to use to create Output or by falsely stating, implying or otherwise misrepresenting your affiliation with any person or entity (including by generating, making available, promoting, labeling, or otherwise using Output in a manner that suggests that such Output was created by or otherwise associated with an individual who was not the creator thereof);
        </li>
        <li>
          <strong>Duplicate, decompile, reverse engineer, disassemble or decode the Services</strong> (including any underlying idea or algorithm), or attempt to do any of the same;
        </li>
        <li>
          <strong>Use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo, image, or other proprietary notation displayed on or through the Services</strong>;
        </li>
        <li>
          <strong>Use automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to modify the Services</strong>;
        </li>
        <li>
          <strong>Exploit the Services or any other user’s Output for any commercial purpose</strong>, except as specifically permitted by the Company in connection with your permitted use of the Services such as your use of your Output for commercial purposes as permitted by these Terms;
        </li>
        <li>
          <strong>Use the Services to create or develop any competing products or services or to power, enable, or train other artificial intelligence and machine learning models, tools or technologies</strong>;
        </li>
        <li>
          <strong>Access or use the Services in any manner that could disable, overburden, damage, disrupt or impair the Services</strong> or interfere with any other party’s access to or use of the Services or use any device, software or routine that causes the same;
        </li>
        <li>
          <strong>Attempt to gain unauthorized access to, interfere with, damage or disrupt the Services</strong>, accounts registered to other users, or the computer systems or networks connected to the Services;
        </li>
        <li>
          <strong>Circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the Services</strong>, including any content filters or other similar measures;
        </li>
        <li>
          <strong>Use any robot, spider, crawlers, scraper, or other automatic device, process, software or queries that intercepts, “mines,” scrapes, extracts, or otherwise accesses the Services</strong> to monitor, extract, copy or collect information or data from or through the Services, or engage in any manual process to do the same;
        </li>
        <li>
          <strong>Introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious or technologically harmful into our systems</strong>;
        </li>
        <li>
          <strong>Submit, transmit, display, generate, create, perform, post or store any content</strong> (including any Input Content or Output) that is unlawful, defamatory, infringing, obscene, excessively violent, pornographic, invasive of privacy or publicity rights, deceptive, misleading, harassing, abusive, hateful, or cruel, or otherwise use the Services or any Output in a manner that is (or in connection with any purpose, initiative, activity, product or service that is) infringing, obscene, excessively violent, harassing, hateful, deceptive, misleading, cruel, abusive, pornographic, or inciting, organizing, promoting or facilitating violence or criminal activities;
        </li>
        <li>
          <strong>Submit any personal information of any person under the age of 13 through the Services</strong>;
        </li>
        <li>
          <strong>Use the Services in violation of any Third Party Software License Terms</strong> (as defined below);
        </li>
        <li>
          <strong>Violate any applicable law or regulation in connection with your access to or use of the Services</strong>; or
        </li>
        <li>
          <strong>Access or use the Services in any way not expressly permitted by these Terms</strong>.
        </li>
      </ul>

      <h3>5.3 Beta Offerings</h3>
      <p>
        From time to time, we may, in our sole discretion, include certain test or beta features or products in the Services (“<strong>Beta Offerings</strong>”) as we may designate from time to time. Your use of any Beta Offering is completely voluntary. The Beta Offerings are provided on an “as is” basis and may contain errors, defects, bugs, or inaccuracies that could cause failures, corruption or loss of data and information from any connected device. You acknowledge and agree that all use of any Beta Offering is at your sole risk. You agree that once you use a Beta Offering, your content or data may be affected such that you may be unable to revert back to a prior non-beta version of the same or similar feature. Additionally, if such reversion is possible, you may not be able to return or restore data created within the Beta Offering back to the prior non-beta version. If we provide you any Beta Offerings on a closed beta or confidential basis, we will notify you of such as part of your use of the Beta Offerings. For any such confidential Beta Offerings, you agree to not disclose, divulge, display, or otherwise make available any of the Beta Offerings without our prior written consent.
      </p>

      <h3>5.4 Use of the App</h3>
      <p>
        You are responsible for providing the mobile device, wireless service plan, software, Internet connections
        and/or other equipment or services that you need to download, install and use the App. We do not guarantee
        that the App can be accessed and used on any particular device or with any particular service plan. We
        do not guarantee that the App or will be available in, or that orders for our paid Services can be placed
        from, any particular geographic location. As part of the Services and to update you regarding the status
        of deliveries, you may receive push notifications, local client notifications, text messages, picture
        messages, alerts, emails or other types of messages directly sent to you in connection with the App
        (“<strong>Push Messages</strong>”). You acknowledge that, when you use the App, your wireless service
        provider may charge you fees for data, text messaging and/or other wireless access, including in
        connection with Push Messages. You have control over the Push Messages settings, and can opt in or out
        of these Push Messages through the Services or through your mobile device’s operating system (with
        the possible exception of infrequent, important service announcements and administrative messages).
        Please check with your wireless service provider to determine what fees apply to your access to and use
        of the App, including your receipt of Push Messages from the Company. You are solely responsible for
        any fee, cost or expense that you incur to download, install and/or use the App on your mobile device,
        including for your receipt of push messages from the Company.
      </p>

      <h3>5.5 Mobile Software from the Apple App Store</h3>
      <p>
        The following terms and conditions apply to you only if you are using the App from the Apple Inc.’s
        (“Apple”) App Store. To the extent the other terms and conditions of these Terms are less restrictive
        than, or otherwise conflict with, the terms and conditions of this paragraph, the more restrictive or
        conflicting terms and conditions in this paragraph apply, but solely with respect to your use of the
        App from the Apple App Store. You acknowledge and agree that these Terms are solely between you and
        the Company, not Apple, and that Apple has no responsibility for the App or content thereof. Your use
        of the App must comply with the App Store’s applicable terms of use. You acknowledge that Apple has no
        obligation whatsoever to furnish any maintenance and support services with respect to the App. In the
        event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple
        will refund the purchase price, if any, for the App to you. To the maximum extent permitted by applicable
        law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other
        claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any
        warranty will be solely governed by these Terms. You and the Company acknowledge that Apple, and Apple’s
        subsidiaries, are third party beneficiaries of these Terms as they relate to your use of the App, and that,
        upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right)
        to enforce these Terms against you as a third party beneficiary thereof.
      </p>

      <h3>5.6 Third Party Software</h3>
      <p>
        The Services include our artificial intelligence and machine learning model(s), which may use certain
        third party software (“<strong>Third Party Software</strong>”). Your access and use of such Third Party
        Software, including through your use of the Services, may be governed by additional terms and
        conditions of the provider of such Third Party Software (“<strong>Third Party Software License Terms</strong>”),
        which you agree to comply with. We will endeavour to notify you of such additional terms and conditions.
      </p>

      <h2>6. Ownership and Content</h2>

      <h3>6.1 Ownership of the Services</h3>
      <p>
        The Services, including their “look and feel” (e.g., text, graphics, images, logos, etc.), proprietary content,
        information and other materials, and any technology used to generate any Output, are protected under copyright,
        trademark and other intellectual property laws. You agree that the Company and/or its licensors own all right,
        title and interest in and to the Services (including any and all intellectual property rights therein) and you
        agree not to take any action(s) inconsistent with such ownership interests. We and our licensors reserve all
        rights in connection with the Services and all related content (other than Your Content (as defined below)),
        including, without limitation, the exclusive right to create derivative works.
      </p>

      <h3>6.2 Ownership of Trademarks</h3>
      <p>
        The Company’s name, trademarks, logo and all related names, logos, product and service names, designs
        and slogans are trademarks of the Company or its affiliates or licensors. Other names, logos, product and
        service names, designs and slogans that appear on the Services are the property of their respective owners,
        who may or may not be affiliated with, connected to, or sponsored by us.
      </p>

      <h3>6.3 Your Content</h3>
      <p>
        In connection with your use of the Services, you may be able to post, upload, or submit content and other
        information owned and controlled by you (such as your Input Content) on or through the Services (“<strong>Your Content</strong>”).
        As between the Company and you, the Company does not claim any ownership in Your Content, including any Output
        generated by you in response to the submission of your Input Content to the Services, provided that the Company or
        its affiliates or their respective licensors own and will continue to own the Services and any and all other software
        or technology that was used to generate any Output. Subject to your compliance with these Terms, including,
        without limitation, Section 6.4, you may use your Output for both personal and commercial purposes, and we
        permit you to download a copy of your Output that consists of an image file for such purposes.
      </p>
      <p>
        In order to operate the Services, we may need to obtain from you certain license rights in Your Content so that
        actions we take in operating the Services are not considered legal violations. Accordingly, by using the Services
        and uploading Your Content, you grant us and our affiliates, successors, assigns, and designees a license to access,
        use, host, cache, store, reproduce, transmit, display, publish, distribute, and modify Your Content to operate,
        improve, promote and provide the Services, including to reproduce, transmit, display, publish, and distribute
        Output based on your Input Content and to train or otherwise improve or modify our artificial intelligence and
        machine learnings model(s) related to the Services. For clarity, this grant of rights includes a grant of all rights
        necessary for Company and our affiliates, successors, assigns, and designees to reproduce, store, modify, distribute,
        create derivative works based on, perform, display, communicate, transmit and otherwise make available any and all
        of Your Content (including all of your Input Content and all resulting Output), in whole or in part, in any and all
        media now known or hereafter developed, in and in connection with the use, monetization, advertising, promotion,
        marketing, and improvement of Company’s products and services, including the Services. You agree that these rights
        and licenses are royalty free, fully paid-up, transferable, sub-licensable, assignable, worldwide, perpetual and
        irrevocable, and include a right for us to make Your Content available to, and pass these rights along to, others,
        and to otherwise permit access to or disclose Your Content to third parties if we determine such access is necessary
        to comply with our legal obligations. For clarity, no compensation or other amounts will be due to you by Company
        or any of our affiliates, successors, assigns, and designees as a result of or otherwise in connection with any
        use of Your Content as permitted by these Terms.
      </p>
      <p>
        As part of the foregoing license grant you agree that other users of the Services may have the right to comment
        on and/or tag Your Content and/or to use, publish, display, modify or include a copy of Your Content as part of
        their own use of the Services, including to download and use your Output for personal, non-commercial purposes
        as set forth in Section 1.2 above; except that the foregoing shall not apply to any of Your Content that you
        post privately for non-public display on the Services. To the fullest extent permitted by applicable law,
        you irrevocably waive any and all so-called “moral rights” or “droit moral” that may exist in or in connection
        with Your Content, and the Company reserves the right, and has absolute discretion, to remove, screen, filter,
        edit, or delete any of Your Content at any time, for any reason, and without notice.
      </p>
      <p>
        By posting or submitting Your Content through the Services, you represent and warrant that you have, or have
        obtained, all rights, licenses, consents, permissions, power and/or authority necessary to post or submit
        Your Content and to grant the rights granted herein for Your Content. You agree that Your Content will not
        contain material subject to copyright or other proprietary rights owned or controlled by any third party,
        unless you have the necessary permission or are otherwise legally entitled to post the material and to grant us
        the license described above.
      </p>

      <h3>6.4 Attribution</h3>
      <p>
        If you exploit or otherwise make any public use of any Output, or any content that includes any Output
        (in whole or in part), including the distribution of Output in the form of or as part of images made available via any 
        third party application, website or platform, then you agree to include a
        credit, notice or other indicator in connection with such use (e.g., in the art title, in the description, etc.)
        that prominently indicates that such Output was generated using the Services; provided that, if you subscribe to
        any of our paid Services, the foregoing obligation shall not apply to any Output generated during your subscription
        to our paid Services. Such notice will be in a form and manner that is reasonable and practicable in light of the
        usage concerned. In addition, you further grant to Company and our affiliates, successors, assigns, and designees
        the worldwide, fully paid-up, sublicensable, assignable, perpetual and irrevocable right (but not the obligation)
        to identify to the public (both on and off the Services) that Output (or any of it) was generated using the Services,
        in such form and manner as we determine in our sole discretion.
      </p>

      <h3>6.5 Notice of Infringement – DMCA (Copyright) Policy</h3>
      <p>
        If you believe that any text, graphics, photos, aonlyjpegs, videos or other materials or works uploaded,
        downloaded or appearing on the Services have been copied in a way that constitutes copyright infringement,
        you may submit a notification to our copyright agent in accordance with 17 USC 512(c) of the Digital
        Millennium Copyright Act (the <strong>"DMCA"</strong>), by providing the following information in writing:
      </p>
      <ul>
        <li>Identification of the copyrighted work that is claimed to be infringed;</li>
        <li>Identification of the allegedly infringing material that is requested to be removed, including a description of where it is located on the Services;</li>
        <li>Information for our copyright agent to contact you, such as an address, telephone number and e-mail address;</li>
        <li>A statement that you have a good faith belief that the identified, allegedly infringing use is not authorized by the copyright owners, its agent or the law;</li>
        <li>A statement that the information above is accurate, and under penalty of perjury, that you are the copyright owner or the authorized person to act on behalf of the copyright owner; and</li>
        <li>The physical or electronic signature of a person authorized to act on behalf of the owner of the copyright or of an exclusive right that is allegedly infringed.</li>
      </ul>
      <p>
        Notices of copyright infringement claims should be sent by e-mail to{' '}
        <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a>. It is our policy, in appropriate circumstances and at our discretion,
        to disable or terminate the accounts of users who repeatedly infringe copyrights or intellectual property rights of others.
      </p>
      <p>
        A user of the Services who has uploaded or posted materials identified as infringing as described above may supply a
        counter-notification pursuant to sections 512(g)(2) and (3) of the DMCA. When we receive a counter-notification,
        we may reinstate the posts or material in question, in our sole discretion. To file a counter-notification with us,
        you must provide a written communication (by fax or regular mail or by email) that sets forth all of the items required
        by sections 512(g)(2) and (3) of the DMCA. Please note that you will be liable for damages if you materially
        misrepresent that content or an activity is not infringing the copyrights of others.
      </p>

      <h3>6.6 Ownership of Feedback</h3>
      <p>
        We welcome feedback, comments and suggestions for improvements to the Services (“<strong>Feedback</strong>”).
        You acknowledge and expressly agree that any contribution of Feedback does not and will not give or grant you
        any right, title or interest in the Services or in any such Feedback. All Feedback becomes the sole and
        exclusive property of the Company, and the Company may use and disclose Feedback in any manner and for
        any purpose whatsoever without further notice or compensation to you and without retention by you of any
        proprietary or other right or claim. You hereby assign to the Company any and all right, title and interest
        (including, but not limited to, any patent, copyright, trade secret, trademark, show-how, know-how,
        moral rights and any and all other intellectual property right) that you may have in and to any and all Feedback.
      </p>

      <hr />

      <h2>7. Third Party Services and Materials</h2>

      <h3>7.1 Use of Third Party Materials in the Services</h3>
      <p>
        Certain Services may display, include or make available content, data, information, applications, software
        or materials from third parties (“<strong>Third Party Materials</strong>”) or provide links to certain third
        party websites. Third Party Materials include the open source software or other third party software, such as
        third party large language models, that are included in the artificial intelligence and machine learning
        models you access or use through the Services. By using the Services, you acknowledge and agree that the
        Company is not responsible for examining or evaluating the content, accuracy, completeness, availability,
        timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third
        Party Materials or websites. We do not warrant or endorse and do not assume and will not have any liability
        or responsibility to you or any other person for any third-party services, Third Party Materials or third-party
        websites, or for any other materials, products, or services of third parties. Third Party Materials and links
        to other websites are provided solely as a convenience to you. Your access and use of Third Party Materials
        may be governed by additional terms and conditions of the provider of such Third Party Materials, which you
        agree to comply with.
      </p>

      <hr />

      <h2>8. Disclaimers, Limitations of Liability, and Indemnification</h2>

      <h3>8.1 Disclaimers</h3>
      <p>
        Your access to and use of the Services and any Output are at your own risk. You understand and agree that
        the Services and any Output are provided to you on an <strong>"AS IS"</strong> and <strong>"AS AVAILABLE"</strong> basis.
        Without limiting the foregoing, to the maximum extent permitted under applicable law, the Company and its parents, affiliates,
        related companies, officers, directors, employees, agents, representatives, partners and licensors
        (collectively, the <strong>"Company Entities"</strong>) <strong>DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT</strong>.
      </p>
      <p>
        The Company Entities make no warranty or representation and disclaim all responsibility and liability for:
      </p>
      <ul>
        <li>The completeness, accuracy, availability, timeliness, security or reliability of the Services and any Output;</li>
        <li>The infringement of the rights of any third party in your use of any Output;</li>
        <li>Any harm to your computer system, loss of data, or other harm that results from your access to or use of the Services;</li>
        <li>The operation or compatibility with any other application or any particular system or device;</li>
        <li>Whether the Services will meet your requirements or be available on an uninterrupted, secure or error-free basis;</li>
        <li>The deletion of, or the failure to store or transmit, Your Content (including Input Content and Output) and other communications maintained by the Services.</li>
      </ul>
      <p>
        The Output generated by the Services is for entertainment purposes only. The Services are not error-free
        and may generate Output containing incorrect information. No advice or information, whether oral or written,
        obtained from the Company Entities or through the Services, will create any warranty or representation not
        expressly made herein. You should not rely on the Services or any Output for advice of any kind, including
        medical, legal, investment, financial or other professional advice. Any Output is not a substitute for advice
        from a qualified professional.
      </p>
      <p>
        Due to the nature of artificial intelligence and machine learning, your Output may not be unique and the
        Services may generate the same or similar output for a third party. Other users may provide similar input
        to the Services and receive the same output from the Services. An input that is submitted by other users
        is not your Input Content (even if is identical to the Input Content you submit to the Services), and an
        output that is requested by and generated for other users is not Your Content.
      </p>
      <p>
        THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING THE STATE OF NEW JERSEY, DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES AS SET FORTH IN SECTION 8.2 BELOW. IF THESE
        LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
        AND YOU MAY HAVE ADDITIONAL RIGHTS.
      </p>
      <p>
        THE COMPANY ENTITIES TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER,
        OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES, INCLUDING ANY OUTPUT.
      </p>
      <p>
        YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING,
        OR OTHERWISE INAPPROPRIATE, NONE OF WHICH THE COMPANY ENTITIES WILL BE RESPONSIBLE FOR.
      </p>

      <h3>8.2 Limitations of Liability</h3>
      <p>
        TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY ENTITIES BE LIABLE:
      </p>
      <ol>
        <li>
          FOR DAMAGES OF ANY KIND, INCLUDING INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
          (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS,
          BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
          SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY
          IN CONNECTION WITH THE SERVICES, OUTPUT OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT
          (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE; OR
        </li>
        <li>
          FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS,
          OUTPUT, OR THE DELIVERY, USE OR PERFORMANCE OF THE SERVICES OR OUTPUT.
        </li>
      </ol>
      <p>
        THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED THE GREATER
        OF ONE HUNDRED DOLLARS ($100.00), OR THE AMOUNT YOU PAID THE COMPANY ENTITIES, IF ANY, IN THE PAST SIX (6)
        MONTHS FOR THE SERVICES (OR OFFERINGS PURCHASED ON THE SERVICES) GIVING RISE TO THE CLAIM. THE FOREGOING
        LIMITATIONS WILL APPLY EVEN IF THE ABOVE-STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
      </p>

      <h3>8.3 Indemnification</h3>
      <p>
        By entering into these Terms and accessing or using the Services, you agree that you shall defend, indemnify
        and hold the Company Entities harmless from and against any and all claims, costs, damages, losses, liabilities
        and expenses (including attorneys’ fees and costs) incurred by the Company Entities arising out of or in connection
        with:
      </p>
      <ul>
        <li>Your violation or breach of any term of these Terms or any applicable law or regulation;</li>
        <li>Your violation of any rights of any third party, including through your generation or use of any Output;</li>
        <li>Your misuse of the Services;</li>
        <li>Your Content, or</li>
        <li>Your negligence or wilful misconduct.</li>
      </ul>
      <p>
        If you are obligated to indemnify any Company Entity hereunder, then you agree that Company (or, at its discretion, the
        applicable Company Entity) will have the right, in its sole discretion, to control any action or proceeding and to determine
        whether the Company wishes to settle, and if so, on what terms, and you agree to fully cooperate with Company in the defense
        or settlement of such claim.
      </p>

      <hr />

      <h2>9. ARBITRATION AND CLASS ACTION WAIVER</h2>

      <h3>9.1 PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</h3>

      <h3>9.2 Informal Process First</h3>
      <p>
        You and the Company agree that in the event of any dispute, either party will first contact the other party and make a good faith, sustained effort to resolve the dispute before resorting to more formal means of resolution, including, without limitation, any court action, after first allowing the receiving party 30 days in which to respond. Both you and the Company agree that this dispute resolution procedure is a condition precedent which must be satisfied before initiating any arbitration against the other party.
      </p>

      <h3>9.3 Arbitration Agreement and Class Action Waiver</h3>
      <p>
        After the informal dispute resolution process, any remaining dispute, controversy, or claim (collectively,{" "}
        <strong>"Claim"</strong>) relating in any way to the Company's Services and/or products, including the Services and any Output, and any use or access or lack of access thereto, will be resolved by arbitration, including threshold questions of arbitrability of the Claim. You and the Company agree that any Claim will be settled by final and binding arbitration, using the English language, administered by JAMS under its Comprehensive Arbitration Rules and Procedures (the{" "}
        <strong>"JAMS Rules"</strong>) then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms). Because your contract with the Company, these Terms, and this Arbitration Agreement concern interstate commerce, the Federal Arbitration Act (<strong>"FAA"</strong>) governs the arbitrability of all disputes. However, the arbitrator will apply applicable substantive law consistent with the FAA and the applicable statute of limitations or condition precedent to suit. Arbitration will be handled by a sole arbitrator in accordance with the JAMS Rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. Any arbitration under these Terms will take place on an individual basis—class arbitrations and class actions are not permitted. You understand that by agreeing to these Terms, you and the Company are each waiving the right to trial by jury or to participate in a class action or class arbitration.
      </p>

      <h3>9.4 Exceptions</h3>
      <p>
        Notwithstanding the foregoing, you and the Company agree that the following types of disputes will be resolved
        in a court of proper jurisdiction:
      </p>
      <ul>
        <li>
          Disputes or claims within the jurisdiction of a small claims court consistent with the jurisdictional and dollar
          limits that may apply, as long as it is brought and maintained as an individual dispute and not as a class, representative, or consolidated action or proceeding;
        </li>
        <li>
          Disputes or claims where the sole form of relief sought is injunctive relief (including public injunctive relief); or
        </li>
        <li>
          Intellectual property disputes.
        </li>
      </ul>

      <h3>9.5 Costs of Arbitration</h3>
      <p>
        Payment of all filing, administration, and arbitrator costs and expenses will be governed by the JAMS Rules,
        except that if you demonstrate that any such costs and expenses owed by you under those rules would be
        prohibitively more expensive than a court proceeding, the Company will pay the amount of any such costs
        and expenses that the arbitrator determines are necessary to prevent the arbitration from being prohibitively
        more expensive than a court proceeding (subject to possible reimbursement as set forth below).
      </p>
      <p>
        Fees and costs may be awarded as provided pursuant to applicable law. If the arbitrator finds that either the
        substance of your claim or the relief sought in the demand is frivolous or brought for an improper purpose (as
        measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
        fees will be governed by the JAMS Rules. In that case, you agree to reimburse the Company for all monies
        previously disbursed by it that are otherwise your obligation to pay under the applicable rules. If you prevail
        in the arbitration and are awarded an amount that is less than the last written settlement amount offered by the
        Company before the arbitrator was appointed, the Company will pay you the amount it offered in settlement. The
        arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at
        any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling
        on the merits.
      </p>

      <h3>9.6 Opt-Out</h3>
      <p>
        You have the right to opt out and not be bound by the arbitration provisions set forth in these Terms by sending written notice of your decision to opt out to <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a>. The notice must be sent to the Company within thirty (30) days of your first registering to use the Services or agreeing to these Terms; otherwise you shall be bound to arbitrate disputes on a non-class basis in accordance with these Terms. If you opt out of only the arbitration provisions, and not also the class action waiver, the class action waiver still applies. You may not opt out of only the class action waiver and not also the arbitration provisions. If you opt out of these arbitration provisions, the Company also will not be bound by them.
      </p>

      <h3>9.7 Waiver of Right to Bring Class Action and Representative Claims</h3>
      <p>
        To the fullest extent permitted by applicable law, you and the Company each agree that any proceeding to resolve any dispute, claim, or controversy will be brought and conducted <strong>ONLY IN THE RESPECTIVE PARTY’S INDIVIDUAL CAPACITY AND NOT AS PART OF ANY CLASS (OR PURPORTED CLASS), CONSOLIDATED, MULTIPLE-PLAINTIFF, OR REPRESENTATIVE ACTION OR PROCEEDING (“CLASS ACTION”)</strong>. You and the Company <strong>AGREE TO WAIVE THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION</strong>. You and the Company <strong>EXPRESSLY WAIVE ANY ABILITY TO MAINTAIN A CLASS ACTION IN ANY FORUM</strong>. If the dispute is subject to arbitration, <strong>THE ARBITRATOR WILL NOT HAVE THE AUTHORITY TO COMBINE OR AGGREGATE CLAIMS, CONDUCT A CLASS ACTION, OR MAKE AN AWARD TO ANY PERSON OR ENTITY NOT A PARTY TO THE ARBITRATION</strong>. Further, you and the Company agree that the <strong>ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS FOR MORE THAN ONE PERSON’S CLAIMS, AND IT MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CLASS ACTION</strong>. For the avoidance of doubt, however, you can seek public injunctive relief to the extent authorized by law and consistent with the Exceptions clause above.
      </p>
      <p>
        <strong>IF THIS CLASS ACTION WAIVER IS LIMITED, VOIDED, OR FOUND UNENFORCEABLE, THEN, UNLESS THE PARTIES MUTUALLY AGREE OTHERWISE, THE PARTIES’ AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING SO LONG AS THE PROCEEDING IS PERMITTED TO PROCEED AS A CLASS ACTION</strong>. If a court decides that the limitations of this paragraph are deemed invalid or unenforceable, any putative class, private attorney general, or consolidated or representative action must be brought in a court of proper jurisdiction and not in arbitration.
      </p>

      <hr />

      <h2>10. Additional Provisions</h2>

      <h3>10.1 SMS Messaging and Phone Calls</h3>
      <p>
        Certain portions of the Services may allow us to contact you via telephone or text messages. You agree that the Company may contact you via telephone or text messages (including by an automatic telephone dialing system) at any of the phone numbers provided by you or on your behalf in connection with your use of the Services, including for marketing purposes. You understand that you are not required to provide this consent as a condition of purchasing any paid Services. You also understand that you may opt out of receiving text messages from us at any time, either by texting a reply with the word “STOP” using the mobile device that is receiving the messages, or by contacting <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a>. If you do not choose to opt out, we may contact you as outlined in our Privacy Policy.
      </p>

      <h3>10.2 Updating These Terms</h3>
      <p>
        We may modify these Terms from time to time, in which case we will update the “Last Revised” date at the top of these Terms. If we make changes that are material, we will use reasonable efforts to attempt to notify you, such as by email and/or by placing a prominent notice on the first page of the Website. However, it is your sole responsibility to review these Terms from time to time to view any such changes. The updated Terms will be effective as of the time of posting or such later date as may be specified in the updated Terms. Your continued access or use of the Services after the modifications have become effective will be deemed your acceptance of the modified Terms. No amendment shall apply to a dispute for which an arbitration has been initiated prior to the change in Terms.
      </p>

      <h3>10.3 Termination of License and Your Account</h3>
      <p>
        If you breach any of the provisions of these Terms, all licenses granted by the Company will terminate automatically. Additionally, the Company may suspend, disable, or delete your Account and/or the Services (or any part of the foregoing) with or without notice, for any or no reason. If the Company deletes your Account for any suspected breach of these Terms by you, you are prohibited from re-registering for the Services under a different name. In the event of Account deletion for any reason, the Company may, but is not obligated to, delete any of Your Content. The Company shall not be responsible for the failure to delete or deletion of Your Content. All sections which by their nature should survive the termination of these Terms shall continue in full force and effect subsequent to and notwithstanding any termination of these Terms by the Company or you. Termination will not limit any of the Company’s other rights or remedies at law or in equity.
      </p>

      <h3>10.4 Modifications to the Services</h3>
      <p>
        The Company has the right, at any time and without liability, to modify, alter, update or eliminate the features,
        navigation, appearance, functionality and other elements of the Services, and any aspect, portion or feature
        thereof, including to modify the Third Party Software you are able to access via the Services.
      </p>

      <h3>10.5 Injunctive Relief</h3>
      <p>
        You agree that a breach of these Terms will cause irreparable injury to the Company for which monetary damages
        would not be an adequate remedy and the Company shall be entitled to equitable relief in addition to any remedies
        it may have hereunder or at law without a bond, other security or proof of damages.
      </p>

      <h3>10.6 California Residents</h3>
      <p>
        If you are a California resident, in accordance with Cal. Civ. Code § 1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
      </p>

      <h3>10.7 Export Laws</h3>
      <p>
        You agree that you will not export or re-export, directly or indirectly, the Services and/or other information
        or materials provided by the Company hereunder, to any country for which the United States or any other relevant
        jurisdiction requires any export license or other governmental approval at the time of export without first
        obtaining such license or approval. In particular, but without limitation, the Services may not be exported or
        re-exported (a) into any U.S. embargoed countries or any country that has been designated by the U.S. Government
        as a “terrorist supporting” country, or (b) to anyone listed on any U.S. Government list of prohibited or
        restricted parties, including the U.S. Treasury Department’s list of Specially Designated Nationals or the
        U.S. Department of Commerce Denied Person’s List or Entity List. By using the Services, you represent and warrant
        that you are not located in any such country or on any such list. You are responsible for and hereby agree to
        comply at your sole expense with all applicable United States export laws and regulations.
      </p>

      <h3>10.8 Miscellaneous</h3>
      <p>
        If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall
        be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
        These Terms and the licenses granted hereunder may be assigned by the Company but may not be assigned by you without
        the prior express written consent of the Company. No waiver by either party of any breach or default hereunder shall
        be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for
        reference only and shall not be read to have any legal effect. The Services are operated by us in the United States.
        Those who choose to access the Services from locations outside the United States do so at their own initiative and
        are responsible for compliance with applicable local laws. These Terms are governed by the laws of the State of New York,
        without regard to conflict of laws rules, and the proper venue for any disputes arising out of or relating to any of the
        same will be the arbitration venue set forth in Section 9, or if arbitration does not apply, then the state and
        federal courts located in New York County, New York.
      </p>

      <h3>10.9 How to Contact Us</h3>
      <p>
        You may contact us regarding the Services or these Terms by e-mail at <a href="mailto:onlyjpegs@gmail.com">onlyjpegs@gmail.com</a>.
      </p>
    </div>
    </ReactModal>
  );
};

export default TermsOfService;
