// src/components/OutputReducer.js

// Removed side-effect imports
// import { saveAs } from 'file-saver';
// import { toast } from 'react-toastify';

export const outputReducer = (state, action) => {
  const JobStatus = {
    QUEUED: 'queued',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    FAILED: 'failed',
  };

  // Helper function to determine if any jobs are still loading
  const calculateIsLoading = (jobs) => {
    return jobs.some(
      (job) =>
        job.status === JobStatus.QUEUED || job.status === JobStatus.PROCESSING
    );
  };

  switch (action.type) {
    // Handle adding a new previous image
    case 'ADD_PREVIOUS_IMAGE': {
      // Check if the image already exists to prevent duplicates
      const imageExists = state.previousImages.includes(action.imageUrl);
      if (imageExists) {
        console.warn(`Image with URL ${action.imageUrl} already exists.`);
        return state;
      }

      return {
        ...state,
        previousImages: [...state.previousImages, action.imageUrl],
        debugInfo: `Added new image: ${action.imageUrl}`,
      };
    }

    // Handle user Pro status updates
    case 'SET_USER_PRO_STATUS':
      return {
        ...state,
        isProUser: action.isProUser,
        debugInfo: `User Pro status updated to ${action.isProUser}`,
      };

    // Handle job submission initiation
    case 'SUBMIT':
      return {
        ...state,
        isLoading: true,
        debugInfo: 'Submitting job to queue...',
      };

      case 'JOB_QUEUED': {
        // Check if the job already exists
        const jobExists = state.jobs.some((job) => job.jobId === action.jobId);
        if (jobExists) {
          console.warn(`JOB_QUEUED: Job with ID ${action.jobId} already exists. Skipping.`);
          return state; // No state change
        }
  
        const newJob = {
          jobId: action.jobId,
          jobName: action.jobName,
          queuePosition: action.queuePosition,
          status: JobStatus.QUEUED,
          imageUrl: null,
          promptId: null,
          isProcessing: false,
          jobType: action.jobType || 'generation',
        };
        console.log(
          `JOB_QUEUED: Adding job ${action.jobId} - "${action.jobName}" at position ${action.queuePosition}`
        );
        return {
          ...state,
          jobs: [newJob, ...state.jobs], // **Prepend** the new job
          isLoading: true,
          debugInfo: `Job "${action.jobName}" queued at position ${action.queuePosition}.`,
        };
      }
  
        // Handle setting the entire job list (e.g., on initial fetch)
        case 'SET_JOBS': {
          if (!Array.isArray(action.jobs)) {
            console.error('SET_JOBS action requires jobs to be an array.');
            return state;
          }

          return {
            ...state,
            jobs: action.jobs,
            isLoading: calculateIsLoading(action.jobs),
            debugInfo: 'Initialized job list from server.',
          };
        }

      case 'UPDATE_JOBS': {
        if (!Array.isArray(action.jobs)) {
          console.error('UPDATE_JOBS action requires jobs to be an array.');
          return state;
        }
      
        let jobsChanged = false;
      
        const updatedJobs = state.jobs.map((currentJob) => {
          const updatedJob = action.jobs.find(
            (job) => job.jobId === currentJob.jobId
          );
      
          if (updatedJob) {
            const hasChanged =
              currentJob.status !== updatedJob.status ||
              currentJob.queuePosition !== updatedJob.queuePosition ||
              currentJob.imageUrl !== updatedJob.imageUrl ||
              currentJob.isProcessing !== updatedJob.isProcessing ||
              currentJob.jobName !== updatedJob.jobName ||
              currentJob.jobType !== updatedJob.jobType;
      
            if (hasChanged) {
              jobsChanged = true;
              console.log(`JOB_UPDATED: Job ${currentJob.jobId} has been updated.`);
              return { ...currentJob, ...updatedJob };
            } else {
              return currentJob;
            }
          } else {
            return currentJob;
          }
        });
      
        // Check for any new jobs
        action.jobs.forEach((job) => {
          const exists = state.jobs.some(
            (currentJob) => currentJob.jobId === job.jobId
          );
          if (!exists) {
            jobsChanged = true;
            console.warn(`UPDATE_JOBS: New job received with ID ${job.jobId}. Adding to jobs.`);
            updatedJobs.unshift(job); // **Prepend** the new job
          }
        });
      
        if (!jobsChanged) {
          // No changes detected, return previous state
          return state;
        }
      
        return {
          ...state,
          jobs: updatedJobs,
          isLoading: calculateIsLoading(updatedJobs),
          debugInfo: 'Updated job statuses.',
        };
      }      

    // Handle adding multiple previous jobs
    case 'ADD_PREVIOUS_JOBS': {
      const newJobs = action.jobs.filter((job) => {
        // Exclude jobs that are already in the jobs array
        return !state.jobs.some((existingJob) => existingJob.jobId === job.jobId);
      });

      if (newJobs.length === 0) {
        console.log('ADD_PREVIOUS_JOBS: No new jobs to add from user history.');
        return state;
      }

      console.log(`ADD_PREVIOUS_JOBS: Adding ${newJobs.length} previous jobs from user history.`);
      const updatedJobsWithPrevious = [...state.jobs, ...newJobs]; // **Append** newJobs
      return {
        ...state,
        jobs: updatedJobsWithPrevious,
        debugInfo: 'Added previous jobs from user history.',
      };
    }

    // Handle clearing images on logout
    case 'CLEAR_PREVIOUS_IMAGES':
      console.log('CLEAR_PREVIOUS_IMAGES: Clearing all jobs and previous images due to user logout.');
      return {
        ...state,
        jobs: [],
        previousImages: [],
        debugInfo: 'Cleared previous images and jobs due to user logout.',
      };

    // Handle errors related to specific jobs
    case 'ERROR': {
      const errorJobIndex = state.jobs.findIndex(
        (job) => job.jobId === action.jobId
      );
      if (errorJobIndex !== -1) {
        const updatedErrorJob = {
          ...state.jobs[errorJobIndex],
          status: JobStatus.FAILED,
          debugInfo: action.message,
        };
        const updatedJobsError = [...state.jobs];
        updatedJobsError[errorJobIndex] = updatedErrorJob;
        console.error(`ERROR: Job "${action.jobId}" failed with message: ${action.message}`);
        return {
          ...state,
          jobs: updatedJobsError,
          isLoading: calculateIsLoading(updatedJobsError),
          debugInfo: `Job "${action.jobId}" failed: ${action.message}`,
        };
      }
      console.warn(`ERROR: Job with ID ${action.jobId} not found.`);
      return state;
    }

    // Handle unknown action types
    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};
