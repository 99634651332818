import React from 'react';
import styles from '../components/styles/LeftPanel.module.css';  // Import the CSS module

function SliderInput({ label, value, onChange, min, max, isLoading }) {
  return (
    <div className={styles.sliderInputContainer}>
      <label className={styles.sliderLabel}>{label}</label>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        className={styles.slider}
      />
      <input
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        className={styles.numberInput}
      />
    </div>
  );
}

export default SliderInput;
