// src/components/RightPanel.js

import React, { useCallback, useEffect, useState } from 'react';
import ImageDisplay from './ImageDisplay';
import JobsSection from './JobsSection';
import styles from '../components/styles/RightPanel.module.css';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { FaArrowLeft, FaArrowRight, FaSave, FaShareAlt, FaTimes } from 'react-icons/fa';

// Custom hook for handling image-related actions
const useImageHandler = ({ setModalOpen, jobs }) => {
  const [currentJobId, setCurrentJobId] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);

  // Open modal with the selected job
  const openModal = useCallback(
    (job) => {
      setCurrentJobId(job.jobId);
      setCurrentJob(job);
      setModalOpen(true);
    },
    [setModalOpen]
  );

  // Close the modal
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setCurrentJobId(null);
    setCurrentJob(null);
  }, [setModalOpen]);

  // Save image using file-saver
  const saveImageHandler = useCallback((imageUrl) => {
    if (imageUrl) {
      const fileName = getFileName(imageUrl);
      saveAs(imageUrl, fileName);
      toast.success('Image saved successfully!');
    }
  }, []);

  // Share image functionality
  const shareImage = useCallback(async (imageUrl) => {
    if (!imageUrl) return;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this image',
          url: imageUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
        toast.error('Failed to share the image.');
      }
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(imageUrl);
        toast.success('Image URL copied to clipboard!');
      } catch (error) {
        console.error('Failed to copy:', error);
        toast.error('Failed to copy the image URL.');
      }
    } else {
      // Fallback method
      const textarea = document.createElement('textarea');
      textarea.value = imageUrl;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success('Image URL copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        toast.error('Failed to copy the image URL.');
      }

      document.body.removeChild(textarea);
    }
  }, []);

  // Get file name from URL
  const getFileName = useCallback((str) => {
    return str.substring(str.lastIndexOf('/') + 1);
  }, []);

  // Navigate between images
  const navigateImages = useCallback(
    (direction) => {
      if (jobs.length === 0 || !currentJobId) return;

      const currentIndex = jobs.findIndex((job) => job.jobId === currentJobId);
      if (currentIndex === -1) return;

      const nextIndex = (currentIndex + direction + jobs.length) % jobs.length;
      const nextJob = jobs[nextIndex];
      setCurrentJobId(nextJob.jobId);
      setCurrentJob(nextJob);
    },
    [jobs, currentJobId]
  );

  // Update currentJob when jobs array changes
  useEffect(() => {
    if (currentJobId !== null) {
      const job = jobs.find((job) => job.jobId === currentJobId);
      if (job) {
        setCurrentJob(job);
      } else {
        // If the job is not found, close the modal
        closeModal();
      }
    }
  }, [currentJobId, jobs, closeModal]);

  return {
    openModal,
    closeModal,
    saveImage: saveImageHandler,
    shareImage,
    currentJob,
    navigateImages,
  };
};

const RightPanel = React.memo(
  ({
    jobs,
    dispatchOutput,
    modalOpen,
    setModalOpen,
    loadMoreImages,
    isLoadingMore,
    hasMoreImages, // Accept hasMoreImages
  }) => {
    const {
      openModal,
      closeModal,
      saveImage,
      shareImage,
      currentJob,
      navigateImages,
    } = useImageHandler({
      setModalOpen,
      jobs,
    });

    // Handle keyboard events for navigation and closing modal
    useEffect(() => {
      if (!modalOpen) return;

      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          navigateImages(-1);
        } else if (event.key === 'ArrowRight') {
          navigateImages(1);
        } else if (event.key === 'Escape') {
          closeModal();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [modalOpen, navigateImages, closeModal]);

    // Preload images to avoid reloading
    useEffect(() => {
      jobs.forEach((job) => {
        if (job.imageUrl) {
          const img = new Image();
          img.src = job.imageUrl;
        }
      });
    }, [jobs]);

    return (
      <div className={styles.rightPanel}>
        {/* Modal for viewing images */}
        {modalOpen && currentJob && (
          <div
            className={`${styles.modalOverlay} ${
              modalOpen ? styles.showModal : styles.hideModal
            }`}
            onClick={closeModal}
            style={{ display: modalOpen ? 'flex' : 'none' }}
          >
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className={`${styles.modalButton} ${styles.closeButton}`}
                onClick={closeModal}
                aria-label="Close"
              >
                <FaTimes />
              </button>
              <div className={styles.imagePromptWrapper}>
                <ImageDisplay imageUrl={currentJob.imageUrl} />
                {currentJob.jobName && (
                  <div className={styles.fullPromptContainer}>
                    <h3>Prompt:</h3>
                    <p className={styles.fullPromptText}>
                      {currentJob.jobName}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.navigation}>
                {/* Previous Button */}
                <button
                  className={styles.modalButton}
                  onClick={() => navigateImages(-1)}
                  aria-label="Previous Image"
                >
                  <FaArrowLeft />
                </button>

                {/* Save Button */}
                <button
                  className={`${styles.modalButton} ${styles.saveButton}`}
                  onClick={() => saveImage(currentJob.imageUrl)}
                  aria-label="Save Image"
                >
                  <FaSave />
                </button>

                {/* Share Button */}
                <button
                  className={`${styles.modalButton} ${styles.shareButton}`}
                  onClick={() => shareImage(currentJob.imageUrl)}
                  aria-label="Share Image"
                >
                  <FaShareAlt />
                </button>

                {/* Next Button */}
                <button
                  className={styles.modalButton}
                  onClick={() => navigateImages(1)}
                  aria-label="Next Image"
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Jobs Section */}
        <JobsSection 
          jobs={jobs} 
          saveImage={saveImage} 
          openModal={openModal} 
          loadMoreImages={loadMoreImages} // Pass the load more function
          isLoadingMore={isLoadingMore}   // Pass the loading state
          hasMoreImages={hasMoreImages} // Pass the hasMoreImages flag
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.jobs === nextProps.jobs &&
      prevProps.modalOpen === nextProps.modalOpen &&
      prevProps.setModalOpen === nextProps.setModalOpen &&
      prevProps.hasMoreImages === nextProps.hasMoreImages &&
      prevProps.isLoadingMore === nextProps.isLoadingMore
    );
  }
);

export default RightPanel;
