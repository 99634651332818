import React, { useState, useEffect, useCallback } from 'react';
import StandardInputs from '../components/StandardInputs';
import SliderInput from '../components/SliderInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from '../components/styles/LeftPanel.module.css';
import prompts from './prompts.json'; // Adjust the path accordingly
import TrainModal from './TrainModal'; // Import the TrainModal component
import { useAuth } from '../contexts/AuthContext'; // Import AuthContext
import { toast } from 'react-toastify'; // Ensure react-toastify is imported

const LeftPanel = ({
  mode,
  setMode,
  outputState,
  handleInputChange,
  handleSubmit,
  inputState,
  isSubmitting,
  batchSize,
  setBatchSize,
  refreshLoras,
  dispatchOutput,
}) => {
  // Update loras to be an object with 'public' and 'private' arrays
  const [loras, setLoras] = useState({ public: [], private: [] });
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [allPrompts, setAllPrompts] = useState([]);
  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false);

  const { currentUser, openAuthModal } = useAuth();

  // Function to fetch LoRAs
  const fetchLoras = useCallback(async () => {
    try {
      if (!currentUser?.uid) {
        setLoras({ public: [], private: [] }); // Clear LoRAs if user is not logged in
        return;
      }

      const uid = encodeURIComponent(currentUser.uid);
      const response = await fetch(`https://api.onlyjpegs.com/get-loras/${uid}`);
      const data = await response.json();
      if (response.ok) {
        setLoras(data.loras);
      } else {
        console.error('Failed to fetch LoRAs:', data.message);
      }
    } catch (error) {
      console.error('Error fetching LoRAs:', error);
    }
  }, [currentUser?.uid]);

  // Fetch LoRAs on component mount and when refreshLoras changes
  useEffect(() => {
    fetchLoras();
  }, [fetchLoras, refreshLoras]);

  // Load prompts from the imported JSON
  useEffect(() => {
    setAllPrompts(prompts);
  }, []);

  // Handle opening and closing of TrainModal
  const openTrainYourOwn = useCallback(() => {
    if (currentUser) {
      setIsTrainModalOpen(true);
    } else {
      openAuthModal(); // Trigger the global AuthModal
    }
  }, [currentUser, openAuthModal]);

  const closeTrainModal = useCallback(() => {
    setIsTrainModalOpen(false);
  }, []);

  // Handlers for incrementing and decrementing batchSize
  const incrementBatchSize = useCallback(() => {
    setBatchSize((prev) => {
      const newValue = prev + 1;
      return newValue > 10 ? 10 : newValue;
    });
  }, [setBatchSize]);

  const decrementBatchSize = useCallback(() => {
    setBatchSize((prev) => {
      const newValue = prev - 1;
      return newValue < 1 ? 1 : newValue;
    });
  }, [setBatchSize]);

  // Handle direct input changes and enforce bounds
  const handleBatchSizeChange = useCallback(
    (e) => {
      const value = Number(e.target.value);
      if (isNaN(value)) return;
      if (value < 1) {
        setBatchSize(1);
      } else if (value > 10) {
        setBatchSize(10);
      } else {
        setBatchSize(value);
      }
    },
    [setBatchSize]
  );

  // Define SVGs for Variation 2
  const iconsVariation2 = {
    small: (
      <svg width="40" height="40" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <rect width="512" height="512" fill="none" stroke="currentColor" strokeWidth="40"/>
      </svg>
    ),
    portrait: (
      <svg width="40" height="60" viewBox="0 0 768 1024" xmlns="http://www.w3.org/2000/svg">
        <rect width="768" height="1024" fill="none" stroke="currentColor" strokeWidth="40"/>
      </svg>
    ),
    large: (
      <svg width="40" height="40" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <rect width="1024" height="1024" fill="none" stroke="currentColor" strokeWidth="40"/>
      </svg>
    ),
    landscape: (
      <svg width="60" height="40" viewBox="0 0 1024 768" xmlns="http://www.w3.org/2000/svg">
        <rect width="1024" height="768" fill="none" stroke="currentColor" strokeWidth="40"/>
      </svg>
    ),
  };

  return (
    <div className={styles.leftPanel}>
      <div className={styles.header}>
        <img
          src="/onlyjpegs.png"
          alt="onlyjpegs"
          className={styles.headerImage}
        />
      </div>

      <StandardInputs
        prompt={inputState.prompt}
        setPrompt={(value) => handleInputChange('prompt', value)}
        isLoading={isSubmitting}
        inputState={inputState}
        handleInputChange={handleInputChange}
        allPrompts={allPrompts} // Pass down allPrompts
      />

      {/* Removed the random prompt button from here */}

      {/* Image Size Selection */}
      <label className={styles.inputLabel}>Image Size</label>

      <div className={styles.quickSelectContainer}>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 512 && inputState.height === 512 ? styles.active : ''
          }`}
          onClick={() => {
            handleInputChange('width', 512);
            handleInputChange('height', 512);
          }}
          disabled={isSubmitting}
        >
          Small<br />512x512
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 768 && inputState.height === 1024 ? styles.active : ''
          }`}
          onClick={() => {
            handleInputChange('width', 768);
            handleInputChange('height', 1024);
          }}
          disabled={isSubmitting}
        >
          Portrait<br />768x1024
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 1024 && inputState.height === 1024 ? styles.active : ''
          }`}
          onClick={() => {
            handleInputChange('width', 1024);
            handleInputChange('height', 1024);
          }}
          disabled={isSubmitting}
        >
          Square<br />1024x1024
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 1024 && inputState.height === 768 ? styles.active : ''
          }`}
          onClick={() => {
            handleInputChange('width', 1024);
            handleInputChange('height', 768);
          }}
          disabled={isSubmitting}
        >
          Landscape<br />1024x768
        </button>
      </div>

      <SliderInput
        label="Width"
        value={inputState.width}
        onChange={(value) => handleInputChange('width', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting}
      />
      <SliderInput
        label="Height"
        value={inputState.height}
        onChange={(value) => handleInputChange('height', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting}
      />

      {/* Styles Dropdown */}
      <label className={styles.inputLabel}>Models/Styles</label>
      <div className={styles.stylesContainer}>
        <select
          value={inputState.lora || ''}
          onChange={(e) => handleInputChange('lora', e.target.value || null)}
          className={styles.dropdown}
          disabled={isSubmitting}
        >
          <option value="">None</option>
          {/* Render user's private models */}
          {loras.private.length > 0 && (
            <optgroup label="Your Models">
              {loras.private.map((loraFile, index) => (
                <option key={`private-${index}`} value={loraFile}>
                  {loraFile.replace('.safetensors', '').toUpperCase()}
                </option>
              ))}
            </optgroup>
          )}
          {/* Render public models */}
          {loras.public.length > 0 && (
            <optgroup label="Public Models">
              {loras.public.map((loraFile, index) => (
                <option key={`public-${index}`} value={loraFile}>
                  {loraFile.replace('.safetensors', '').toUpperCase()}
                </option>
              ))}
            </optgroup>
          )}
        </select>
      </div>

      {/* Train Your Own Button */}
      <button
        onClick={openTrainYourOwn}
        className={styles.trainYourOwnButton}
        disabled={isSubmitting}
      >
        Train Your Model
      </button>

      <label className={styles.inputLabel}>Number of Images</label>
      <div className={styles.batchInputContainer}>
        {batchSize > 1 && (
          <button
            onClick={decrementBatchSize}
            className={`${styles.batchButton} ${styles.decrementButton}`}
            disabled={isSubmitting}
            aria-label="Decrease number of images"
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        )}
        <input
          type="number"
          value={batchSize}
          onChange={handleBatchSizeChange}
          min={1}
          max={10}
          className={styles.batchInput}
          disabled={isSubmitting}
        />
        {batchSize < 10 && (
          <button
            onClick={incrementBatchSize}
            className={`${styles.batchButton} ${styles.incrementButton}`}
            disabled={isSubmitting}
            aria-label="Increase number of images"
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        )}
      </div>

      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={styles.button}
      >
        {isSubmitting ? 'Submitting...' : 'Generate'}
      </button>

      {/* Train Modal */}
      <TrainModal
        isOpen={isTrainModalOpen}
        onRequestClose={closeTrainModal}
        dispatchOutput={dispatchOutput}
      />
    </div>
  );
};

export default React.memo(LeftPanel); // Memoize LeftPanel to prevent unnecessary re-renders
