// src/components/ImageDisplay.js

import React from 'react';
import styles from '../components/styles/RightPanel.module.css';

// Memoize the ImageDisplay component
const ImageDisplay = React.memo(({ imageUrl }) => {
  return (
    <div className={styles.imageWrapper}>
      <img
        src={imageUrl}
        alt="Full View"
        className={styles.modalImage}
      />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.imageUrl === nextProps.imageUrl;
});

export default ImageDisplay;
