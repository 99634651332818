// PrivacyPolicy.js
import React from 'react';
import ReactModal from 'react-modal';
import '../components/styles/Pricing.css'; // Import Pricing.css for close button styling
import styles from '../components/styles/PrivacyPolicy.module.css';


const PrivacyPolicy = ({ isOpen, onRequestClose }) => {
  return (
    <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Privacy Policy"
    className="pricingModal" // Reuse Pricing modal styles or create specific styles
    overlayClassName="pricingModalOverlay"
    closeTimeoutMS={300}
    ariaHideApp={false} // Ensure accessibility settings as per your app's requirements
  >
    <div className={styles.privacyPolicyContainer}>
    <button onClick={onRequestClose} className="closeModalButton" aria-label="Close Pricing Modal">
    &times;
      </button>
      <h1>Privacy Policy</h1>
      <p className={styles.lastRevised}>Last Revised on September 26, 2024</p>

      <p>This Privacy Notice describes how OnlyJPEGs ("we", "us", "our") collects, uses, and discloses information about you when you use our website (onlyjpegs.com), applications, services, tools, and features, or otherwise interact with us (collectively, the "Services"). For the purposes of this Privacy Notice, "you" and "your" means you as the user of the Services.</p>
      
      <p>Please read this Privacy Notice carefully. By using any of the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Notice. If you do not agree to this Privacy Notice, please do not use or access the Services.</p>
      
      <h2>CHANGES TO THIS PRIVACY NOTICE</h2>
      <p>We may modify this Privacy Notice from time to time, in which case we will update the "Last Revised" date at the top of this Privacy Notice. If we make material changes to how we use or disclose information we collect, we will use reasonable efforts to notify you (such as by emailing you at the last email address you provided us, by posting notice of such changes on the Services, or by other means consistent with applicable law) and will take additional steps as required by applicable law. If you do not agree to any updates to this Privacy Notice, please do not continue using or accessing the Services.</p>
      
      <h2>COLLECTION AND USE OF INFORMATION</h2>
      <p>When you use or access the Services, we collect certain categories of information about you from a variety of sources.</p>
      
      <h3>Information You Provide to Us</h3>
      <ul>
        <li><strong>Basic contact details:</strong> Such as your name and email address. We use this information to provide the Services and to communicate with you.</li>
        <li><strong>Account information:</strong> Such as username, password, and any security questions and answers you select. We use this information to provide the Services and to maintain and secure your account with us.</li>
        <li><strong>Payment information:</strong> Such as credit or debit card details and billing address, which we collect using a third-party payment processor.</li>
        <li><strong>User-generated content:</strong> Such as images, photos, and other content submitted by you through our artificial intelligence-powered prompt fields ("input").</li>
      </ul>

      <h3>Information Collected Automatically</h3>
      <p>We may also use cookies or other tracking technologies to automatically collect certain information about your interactions with the Services. This includes:</p>
      <ul>
        <li>Device information, such as device type, operating system, unique device identifier, and Internet Protocol (IP) address.</li>
        <li>Location information, such as approximate location, if you choose to provide it.</li>
        <li>Other information regarding your interaction with the Services, such as browser type, log data, date and time stamps, and clickstream data.</li>
      </ul>

      <h3>Information Collected From Other Sources</h3>
      <p>We may obtain information about you from outside sources, including information that we collect directly from third parties and information from third parties that you choose to share with us. Such information includes:</p>
      <ul>
        <li>Analytics data we receive from analytics providers like Google Analytics.</li>
        <li>Information we receive when you choose to link any third-party platforms to your account or otherwise engage with us on such platforms, such as Google, Facebook, or Twitter.</li>
      </ul>
      
      <h2>DISCLOSURE OF YOUR INFORMATION</h2>
      <p>We may disclose your information for legitimate purposes subject to this Privacy Notice, including to the following categories of third parties:</p>
      <ul>
        <li>Vendors or other service providers who help us provide the Services.</li>
        <li>Third parties to whom you request or direct us to disclose information.</li>
        <li>Professional advisors, such as auditors, law firms, or accounting firms.</li>
        <li>Third parties in connection with or anticipation of an asset sale, merger, bankruptcy, or other business transaction.</li>
      </ul>

      <h2>CHILDREN'S PRIVACY</h2>
      <p>Our Services are not intended for children under the age of 13, and we do not seek or knowingly collect any personal information from children under 13 years of age. If we become aware that we have unknowingly collected information from a child under 13, we will make commercially reasonable efforts to delete such information from our database.</p>
      
      <h2>DATA SECURITY AND RETENTION</h2>
      <p>Despite our reasonable efforts to protect your information, no security measures are impenetrable, and we cannot guarantee "perfect security." Any information you send to us electronically, while using the Services or otherwise interacting with us, may not be secure while in transit. We recommend that you do not use unsecured channels to send us sensitive or confidential information.</p>
      
      <h2>HOW TO CONTACT US</h2>
      <p>Should you have any questions about our privacy practices or this Privacy Notice, please email us at onlyjpegs@gmail.com.</p>
    </div>
    </ReactModal>
  );
};

export default PrivacyPolicy;
