// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_test_51ODEv8FJM2kqxHtJJBC7q0fcms74B2QoZs4GTep3bfCQGXPZM1ofhJ45ST7IcI5iitNJoZ9gJPxnUJfnAidyeg8k00SnMDwKz2'); // Replace with your actual Stripe publishable key

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
        <ToastContainer />
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
