import React, { useState, useEffect } from 'react';

function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      console.log('You are online');
    };

    const handleOffline = () => {
      setIsOnline(false);
      console.log('You are offline');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    !isOnline && (
      <div className="networkStatus">
        You are currently offline. Some features may not be available.
      </div>
    )
  );
}

export default NetworkStatus;
