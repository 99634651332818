// src/components/AuthModal.js
import React from 'react';
import ReactModal from 'react-modal';
import { auth } from '../components/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './styles/AuthModal.css';

const AuthModal = ({ isOpen, onRequestClose }) => {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      onRequestClose(); // Close modal on successful sign-in
    } catch (error) {
      console.error('Google Sign-In Error:', error.message);
      // Optionally, display a toast or error message here
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Authentication Modal"
      className="authModal"
      overlayClassName="authModalOverlay"
      closeTimeoutMS={300} // For smooth transition
      ariaHideApp={false}
    >
      <button
        onClick={onRequestClose}
        className="closeModalButton"
        aria-label="Close Authentication Modal"
      >
        &times;
      </button>
      <div className="authModalContent">
        <div className="authModalImage">
          <img
            src="./login.png"
            alt="Background"
          />
        </div>
        <div className="authModalBody">
          <div className="appLogo">
            <img src="/onlyjpegs.png" alt="onlyjpegs" className={"headerImage"} />
          </div>
          <button
            className="googleButton"
            onClick={handleGoogleSignIn}
            aria-label="Sign in with Google"
          >
            <img
              src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
              alt="Google Logo"
              className="googleLogo"
            />
            <span className="googleButtonText">Continue with Google</span>
          </button>
          <p className="termsText">
            By signing in, you are agreeing to the{' '}
            <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </ReactModal>
  );
};

export default AuthModal;
