import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles/Header.module.css';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import Pricing from '../pages/Pricing';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

const Header = ({ handleLogin, handleLogout, isAuthModalOpen, genConnected, trainConnected }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { currentUser, isProUser, loading } = useAuth();

  const navigate = useNavigate(); // Initialize useNavigate

  const openPricingModal = () => {
    // Instead of setting local state, navigate to '/pricing'
    navigate('/pricing');
  };
  
  const closePricingModal = () => {
    // Close the modal by navigating back to '/'
    navigate('/');
  };

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };
  
  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };
  
  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  // Toggle Dropdown Menu
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
    console.log(`Header: Dropdown visibility set to ${!dropdownVisible}`);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownVisible &&
        !event.target.closest(`.${styles.dropdownMenu}`) &&
        !event.target.closest(`.${styles.hamburgerMenu}`)
      ) {
        setDropdownVisible(false);
        console.log('Header: Dropdown closed due to outside click');
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownVisible]);

  // Log the authentication and user data
  useEffect(() => {
    console.log('Header: Current User:', currentUser);
    console.log('Header: Is Pro User:', isProUser);
  }, [currentUser, isProUser]);

  if (loading) {
    // Optionally, render a loading indicator or return null to avoid rendering prematurely
    return (
      <div className={styles.header}>
        <div className={styles.loading}>Loading...</div>
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <a
        href="http://onlyjpegs.com/"
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.headerText}><div className={styles.title}>Create</div><div className={styles.title}>any image</div></div>
      </a>
      
      {/* Conditionally Render Connection Status Indicators */}
      {currentUser && (
        <div className={styles.connectionStatus}>
          <div className={styles.statusItem}>
            <span>Generator:</span>
            <div
              className={`${styles.statusLight} ${genConnected ? styles.connected : styles.disconnected}`}
              aria-label={`Generator is ${genConnected ? 'connected' : 'disconnected'}`}
              title={`Generator is ${genConnected ? 'connected' : 'disconnected'}`}
            ></div>
          </div>
          <div className={styles.statusItem}>
            <span>Trainer:</span>
            <div
              className={`${styles.statusLight} ${trainConnected ? styles.connected : styles.disconnected}`}
              aria-label={`Trainer is ${trainConnected ? 'connected' : 'disconnected'}`}
              title={`Trainer is ${trainConnected ? 'connected' : 'disconnected'}`}
            ></div>
          </div>
        </div>
      )}
      
      <div className={styles.authControls}>
        <div className={styles.authContainer}>
          {currentUser ? (
            <>
              <span className={styles.welcomeText}>
                {currentUser.displayName
                  ? `Welcome, ${currentUser.displayName}!`
                  : currentUser.email
                  ? `Welcome, ${currentUser.email}.`
                  : currentUser.phoneNumber
                  ? `Welcome, ${currentUser.phoneNumber}.`
                  : 'Welcome'}
              </span>
              {/* Display Credits */}
              <span
                className={styles.creditsText}
                onClick={openPricingModal} // Add onClick handler
                style={{ cursor: 'pointer' }} // Change cursor to pointer
                role="button" // Accessibility: indicate it's clickable
                tabIndex={0} // Make it focusable
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openPricingModal();
                  }
                }}
                aria-label="Open Pricing Modal"
              >
                Credits: {currentUser.credits !== undefined ? currentUser.credits : '0'}
              </span>
              <button onClick={handleLogout} className={styles.button}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleLogin}
                className={styles.button}
                disabled={isAuthModalOpen}
              >
                {isAuthModalOpen ? 'Signing In...' : 'Login'}
              </button>
            </>
          )}
        </div>
        <div className={styles.hamburgerMenu} onClick={toggleDropdown}>
          &#9776; {/* Hamburger icon */}
        </div>
      </div>

      {/* Dropdown Menu */}
      <div
        className={`${styles.dropdownMenu} ${
          dropdownVisible ? styles.show : ''
        }`}
      >
      {currentUser && currentUser.uid && (
          <button
            className={styles.dropdownItem}
            onClick={() => {
              setDropdownVisible(false);
              window.open('https://billing.stripe.com/p/login/6oEbMw0fWeRk3NSeUU', '_blank');
            }}
          >
            Manage Subscription
          </button>
        )}

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openPricingModal();
          }}
        >
          Pricing
        </button>

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openPrivacyModal();
          }}
        >
          Privacy Policy
        </button>

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openTermsModal();
          }}
        >
          Terms of Service
        </button>

        {/* Login/Logout Button in Dropdown for Mobile */}
        <div className={styles.dropdownAuthContainer}>
          {currentUser ? (
            <button
              onClick={() => {
                handleLogout();
                setDropdownVisible(false);
              }}
              className={styles.dropdownAuthButton}
            >
              Logout
            </button>
          ) : (
            <button
              onClick={() => {
                handleLogin();
                setDropdownVisible(false);
              }}
              className={styles.dropdownAuthButton}
            >
              Login
            </button>
          )}
        </div>
      </div>

      {/* Pricing Modal */}
      {/* Remove local Pricing modal since App handles it via routing */}
      {/* If you want to keep it for some reason, ensure it's not conflicting */}
      {/* <Pricing
        isOpen={isPricingModalOpen}
        onRequestClose={closePricingModal}
      /> */}

      {/* Privacy Policy Modal */}
      <PrivacyPolicy
        isOpen={isPrivacyModalOpen}
        onRequestClose={closePrivacyModal}
      />

      {/* Terms of Service Modal */}
      <TermsOfService
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
      />

      {/* Handle Errors */}
      {/* Removed Firestore-related error handling as it's no longer needed */}
    </div>
  );
};

export default Header;
