// src/components/JobCard.js

import React from 'react';
import styles from '../components/styles/RightPanel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const JobCard = React.memo(({ job, saveImage, openModal }) => {
  const status = job.status?.toLowerCase() || 'error, please refresh.';
  const isTrainingJob = job.jobType === 'training';

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  
  // Render placeholder content based on status and job type
  const renderPlaceholderContent = () => {
    if (isTrainingJob) {
      if (status === 'queued') {
        return (
          <div className={styles.placeholderContent}>
            <div className={styles.spinnerBoxQueued}>
              <div className={styles.circleBorder}>
                <div className={styles.circleCore}></div>
              </div>
            </div>
            <p>Queued</p>
            <p className={styles.waitTime}>Queue Position: {job.queuePosition}</p>
          </div>
        );
      } else if (status === 'processing') {
        return (
          <div className={styles.placeholderContent}>
            <div className={styles.spinnerBoxProcessing}>
              <div className={styles.leoBorder1}>
                <div className={styles.leoCore1}></div>
              </div>
              <div className={styles.leoBorder2}>
                <div className={styles.leoCore2}></div>
              </div>
            </div>
            <p>Processing</p>
          </div>
        );
      } else if (status === 'completed') {
        return (
          <div className={styles.placeholderContent}>
            <p>Completed</p>
          </div>
        );
      } else {
        return <p>{capitalizeFirstLetter(status)}</p>;
      }
    } else {
      switch (status) {
        case 'queued':
          return (
            <div className={styles.placeholderContent}>
              {/* Custom Queued Spinner Animation */}
              <div className={styles.spinnerBoxQueued}>
                <div className={styles.circleBorder}>
                  <div className={styles.circleCore}></div>
                </div>
              </div>
              <p>Queued</p>
              {/* Display Queue Position */}
              <p className={styles.waitTime}>Queue Position: {job.queuePosition}</p>
            </div>
          );
        case 'processing':
          return (
            <div className={styles.placeholderContent}>
              {/* Custom Processing Spinner Animation */}
              <div className={styles.spinnerBoxProcessing}>
                <div className={styles.leoBorder1}>
                  <div className={styles.leoCore1}></div>
                </div>
                <div className={styles.leoBorder2}>
                  <div className={styles.leoCore2}></div>
                </div>
              </div>
              <p>Processing</p>
            </div>
          );
        default:
          return <p>{capitalizeFirstLetter(status)}</p>;
      }
    }
  };

  // Handle copying the prompt to clipboard
  const handleCopyPrompt = (e) => {
    e.stopPropagation();
    if (job.jobName) {
      navigator.clipboard.writeText(job.jobName);
      toast.success('Prompt copied!');
    } else {
      toast.error('No prompt to copy.');
    }
  };

  return (
    <div
      className={`${styles.jobCardWrapper} ${styles[status]} ${
        isTrainingJob ? styles.trainingJob : ''
      }`}
    >
      <div
        className={styles.jobCard}
        onClick={() => {
          if (isTrainingJob) {
            // Handle training job click if needed
            // For example, open a training-specific modal
            // openTrainingModal(job);
          } else if (job.imageUrl) {
            openModal(job);
          }
        }}
      >
        {isTrainingJob ? (
          renderPlaceholderContent()
        ) : job.imageUrl ? (
          <img
            src={job.imageUrl}
            alt="Generated"
            className={styles.jobImage}
            loading="lazy"
          />
        ) : (
          renderPlaceholderContent()
        )}

        {/* Prompt Container */}
        {!isTrainingJob && (
          <div
            className={styles.promptContainer}
            onClick={(e) => handleCopyPrompt(e)}
          >
            <p className={styles.promptText}>
              <strong>Prompt:</strong>{' '}
              {truncateText(job.jobName || 'Unnamed Job', 60)}
            </p>
            {/* Prompt Overlay */}
            <div className={styles.promptOverlay}>
              <button
                className={styles.copyPromptButton}
                onClick={handleCopyPrompt}
                aria-label="Copy Prompt"
              >
                <FontAwesomeIcon icon={faCopy} /> Copy Prompt
              </button>
            </div>
          </div>
        )}
        {/* Download Icon */}
        {!isTrainingJob && job.imageUrl && (
          <button
            className={styles.downloadIcon}
            onClick={(e) => {
              e.stopPropagation();
              saveImage(job.imageUrl);
            }}
            aria-label="Download Image"
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        )}
      </div>
    </div>
  );
});

export default JobCard;
