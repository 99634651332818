// src/components/JobsSection.js

import React, { useEffect, useRef } from 'react';
import JobCard from './JobCard';
import styles from '../components/styles/RightPanel.module.css';
import PropTypes from 'prop-types';

// Custom comparison function to prevent unnecessary re-renders
function areEqual(prevProps, nextProps) {
  // Deep comparison for jobs array
  const jobsEqual =
    prevProps.jobs.length === nextProps.jobs.length &&
    prevProps.jobs.every((prevJob, index) => {
      const nextJob = nextProps.jobs[index];
      return (
        prevJob.jobId === nextJob.jobId &&
        prevJob.status === nextJob.status &&
        prevJob.imageUrl === nextJob.imageUrl &&
        prevJob.jobName === nextJob.jobName
      );
    });

  return (
    jobsEqual &&
    prevProps.saveImage === nextProps.saveImage &&
    prevProps.openModal === nextProps.openModal &&
    prevProps.hasMoreImages === nextProps.hasMoreImages &&
    prevProps.isLoadingMore === nextProps.isLoadingMore
  );
}

const JobsSection = React.memo(
  ({
    jobs = [],
    saveImage,
    openModal,
    loadMoreImages,
    isLoadingMore,
    hasMoreImages,
  }) => {
    const loadMoreRef = useRef(null);
    const jobsSectionRef = useRef(null);

    // Set up Intersection Observer for lazy loading
    useEffect(() => {
      if (!jobsSectionRef.current) return;

      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !isLoadingMore && hasMoreImages) {
            loadMoreImages(); // Trigger image loading when user scrolls to the bottom
          }
        },
        { root: jobsSectionRef.current, threshold: 0.5 } // Use jobsSection as root
      );

      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current);
      }

      return () => {
        if (loadMoreRef.current) {
          observer.unobserve(loadMoreRef.current);
        }
        observer.disconnect();
      };
    }, [loadMoreImages, isLoadingMore, hasMoreImages]);

    // Load more images if content doesn't fill container
    useEffect(() => {
      if (jobsSectionRef.current && hasMoreImages) {
        const { scrollHeight, clientHeight } = jobsSectionRef.current;
        if (scrollHeight <= clientHeight && !isLoadingMore) {
          loadMoreImages();
        }
      }
    }, [jobs.length, isLoadingMore, loadMoreImages, hasMoreImages]);

    return (
      <div className={styles.jobsSection} ref={jobsSectionRef}>
        <h2>My Images</h2>
        {jobs.length === 0 ? (
          <p>Create your first image!</p>
        ) : (
          <div className={styles.jobsGrid}>
            {jobs.map((job) => (
              <JobCard
                key={job.jobId} // Ensure jobId is unique
                job={job}
                saveImage={saveImage}
                openModal={openModal}
              />
            ))}
            {/* Load more trigger for infinite scrolling */}
            <div
              ref={loadMoreRef}
              style={{ height: '50px', visibility: 'hidden' }}
            ></div>
          </div>
        )}
    
        {isLoadingMore && <p>Loading more images...</p>}
      </div>
    );
  },
  areEqual
);

// Define PropTypes
JobsSection.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      jobId: PropTypes.string.isRequired,
      jobName: PropTypes.string,
      imageUrl: PropTypes.string,
      status: PropTypes.string,
      jobType: PropTypes.string,
    })
  ).isRequired,
  saveImage: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  loadMoreImages: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  hasMoreImages: PropTypes.bool.isRequired,
};

export default JobsSection;
