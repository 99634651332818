// src/contexts/AuthContext.js

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { auth, db } from '../components/firebaseConfig'; // Ensure correct import paths
import { onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Assuming you use react-toastify for notifications

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProUser, setIsProUser] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false); // State to control AuthModal

  // Function to open AuthModal
  const openAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  // Function to close AuthModal
  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  // Function to handle logout (if not already defined)
  const handleLogout = useCallback(async () => {
    try {
      await auth.signOut();
      toast.success('Successfully logged out!');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Failed to log out. Please try again.');
    }
  }, []);

  useEffect(() => {
    let unsubscribeUser = null;

    // Listen for auth state changes
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      // Clean up previous Firestore listener if any
      if (unsubscribeUser) {
        unsubscribeUser();
        unsubscribeUser = null;
      }

      if (user) {
        try {
          const userRef = doc(db, 'onlyjpegs', user.uid);

          // Check if user document exists
          const userSnap = await getDoc(userRef);
          const isNewUser = !userSnap.exists();

          if (isNewUser) {
            // Initialize user document with default values
            await setDoc(userRef, {
              email: user.email || null,
              displayName: user.displayName || null,
              phoneNumber: user.phoneNumber || null,
              photoURL: user.photoURL || null,
              isProUser: false,
              credits: 200, // Initialize credits
              subscription: 'Free', // Set subscription to 'Free'
              subscriptionStartDate: serverTimestamp(), // Initialize subscriptionStartDate
              lastReplenished: serverTimestamp(), // Initialize lastReplenished
              createdAt: serverTimestamp(), // Optional: Record when the user was created
              processedEvents: [], // Initialize processedEvents array if needed
            });
          } else {
            // Update user document with latest Auth user data
            await setDoc(
              userRef,
              {
                email: user.email || null,
                displayName: user.displayName || null,
                phoneNumber: user.phoneNumber || null,
                photoURL: user.photoURL || null,
                // Only update fields that might have changed
              },
              { merge: true }
            );
          }

          // Set up real-time listener for user document
          unsubscribeUser = onSnapshot(
            userRef,
            (docSnap) => {
              if (docSnap.exists()) {
                const userData = docSnap.data();
                setCurrentUser({ uid: user.uid, ...userData });
                setIsProUser(userData.isProUser || false);
              }
              // Set loading to false after receiving the first snapshot
              setLoading(false);
            },
            (error) => {
              console.error('AuthContext: Real-time listener error:', error);
              setLoading(false); // Ensure loading is false even on error
            }
          );
        } catch (error) {
          console.error('AuthContext: Error setting user data:', error);
          setCurrentUser(null);
          setIsProUser(false);
          setLoading(false); // Ensure loading is false even on error
        }
      } else {
        // User is signed out
        setCurrentUser(null);
        setIsProUser(false);
        setLoading(false); // Set loading to false when user is not authenticated
      }
    });

    // Cleanup function
    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
      if (unsubscribeUser) unsubscribeUser();
    };
  }, [handleLogout]);

  const value = {
    currentUser,
    loading,
    isAuthModalOpen,
    isProUser,
    openAuthModal,
    closeAuthModal,
    handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
