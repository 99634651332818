import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import axios from 'axios';
import './App.css';
import { toast } from 'react-toastify';
import NetworkStatus from './components/NetworkStatus';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import Header from './components/Header';
import { outputReducer } from './components/OutputReducer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import io from 'socket.io-client';
import AuthModal from './components/AuthModal';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TermsOfService from './pages/TermsOfService';
import ReactModal from 'react-modal';
import { useAuth } from './contexts/AuthContext';
import { signOut } from 'firebase/auth';
import Pricing from './pages/Pricing';
import { auth, functions } from './components/firebaseConfig';
import PaymentSuccess from './pages/PaymentSuccess';
import TrainModal from './components/TrainModal';
import { httpsCallable } from 'firebase/functions';

ReactModal.setAppElement('#root');

// Custom hook to determine if the device is mobile
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleChange = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return isMobile;
}

// Button to collapse/show job panel on mobile devices
const CollapseButton = React.memo(({ isPanelCollapsed, togglePanel }) => (
  <button
    className={`collapseButton ${isPanelCollapsed ? 'active' : ''}`}
    onClick={togglePanel}
    aria-label={isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
  >
    {isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
  </button>
));

function App() {
  
  const location = useLocation();
  const navigate = useNavigate();

  // Authentication Context
  const {
    currentUser,
    isProUser,
    authLoading,
    isAuthModalOpen,
    closeAuthModal,
    openAuthModal,
  } = useAuth();

  // Device Detection
  const isMobile = useIsMobile();

  // UI State Variables
  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

    // Output State with Reducer
    const [outputState, dispatchOutput] = useReducer(outputReducer, {
      imageUrl: '',
      previousImages: [],
      debugInfo: '',
      isLoading: false,
      queuePosition: null,
      totalJobs: null,
      jobId: null,
      promptId: null,
      jobs: [], // Unified jobs array
      isProUser: false,
    });

  // Job and Image Handling
  const [page, setPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [batchSize, setBatchSize] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [refreshLoras, setRefreshLoras] = useState(false);
  const [mode, setMode] = useState('Standard');

  // Input State
  const [inputState, setInputState] = useState({
    clothing: '',
    scene: '',
    background: '',
    text: '',
    prompt: '',
    width: 512,
    height: 512,
    lora: 'None',
    instruction: null,
  });

  const outputStateRef = useRef(outputState);
  useEffect(() => {
    outputStateRef.current = outputState;
  }, [outputState]);

  // Handle New Image Addition
  const handleNewImage = useCallback((newImageUrl) => {
    dispatchOutput({
      type: 'ADD_PREVIOUS_IMAGE',
      imageUrl: newImageUrl,
    });
  }, []);

  // Function to fetch user jobs
  const fetchUserJobs = useCallback(async (uid) => {
    try {
      const response = await axios.get(
        `https://api.onlyjpegs.com/user-jobs/${encodeURIComponent(uid)}`,
        { withCredentials: true }
      );

      console.log(`Fetched jobs for UID ${uid}:`, response.data.jobs);

      if (response.data.jobs && Array.isArray(response.data.jobs)) {
        const jobs = response.data.jobs;

        dispatchOutput({
          type: 'SET_JOBS',
          jobs: jobs,
        });
      } else {
        console.warn(`No jobs found for user with UID ${uid} or data format is incorrect.`);
      }
    } catch (error) {
      console.error(`Failed to fetch jobs for user with UID ${uid}:`, error);
    }
  }, []);

  // Fetch User Images with Pagination
  const fetchUserImages = useCallback(
    async (uid, start = 0, limit = 20) => {
      setIsLoadingMore(true);
      try {
        const response = await axios.get(
          `https://api.onlyjpegs.com/user-images/${encodeURIComponent(
            uid
          )}?start=${start}&limit=${limit}`,
          { withCredentials: true }
        );

        if (response.data.images && Array.isArray(response.data.images)) {
          const newJobs = response.data.images.map((imageData) => ({
            jobId: imageData.jobId || imageData.image_url, // Prefer jobId if available
            jobName: imageData.prompt || 'Previous Job',
            imageUrl: imageData.image_url,
            status: 'completed',
            jobType: 'generation',
          }));

          dispatchOutput({
            type: 'ADD_PREVIOUS_JOBS',
            jobs: newJobs,
          });

          if (newJobs.length < limit) {
            setHasMoreImages(false); // No more images to load
          }
        } else {
          console.warn(
            `No images found for user with UID ${uid} or data format is incorrect.`
          );
          setHasMoreImages(false);
        }
      } catch (error) {
        console.error(
          `Failed to fetch images for user with UID ${uid}:`,
          error
        );
        setHasMoreImages(false);
      } finally {
        setIsLoadingMore(false);
      }
    },
    []
  );

  // Load More Images
  const loadMoreImages = useCallback(() => {
    if (currentUser && hasMoreImages) {
      const uid = currentUser.uid;
      fetchUserImages(uid, page * 20, 20); // Fetch the next 20 images
      setPage((prev) => prev + 1); // Increment the page
    }
  }, [currentUser, page, fetchUserImages, hasMoreImages]);

  // Initial Load and Handle User Logout
  useEffect(() => {
    if (currentUser) {
      const uid = currentUser.uid;
      setPage(1); // Start from page 1
      setHasMoreImages(true); // Reset the hasMoreImages flag
      fetchUserJobs(uid); // Fetch all jobs
      fetchUserImages(uid); // Fetch initial images
    } else {
      setPage(0);
      setHasMoreImages(true);
      dispatchOutput({ type: 'CLEAR_PREVIOUS_IMAGES' }); // Clear images and jobs on logout
      localStorage.removeItem('user_jobs'); // Clear stored jobs if using localStorage
    }
  }, [currentUser, fetchUserJobs, fetchUserImages]);

  // Initial Load and Handle User Logout
  useEffect(() => {
    if (currentUser) {
      const uid = currentUser.uid;
      setPage(1); // Start from page 1
      setHasMoreImages(true); // Reset the hasMoreImages flag
      fetchUserImages(uid);
      fetchUserJobs(uid);
    } else {
      setPage(0);
      setHasMoreImages(true);
      dispatchOutput({ type: 'CLEAR_PREVIOUS_IMAGES' }); // Clear images on logout
    }
  }, [currentUser, fetchUserImages, fetchUserJobs]);

  // Authentication Handlers
  const handleLogin = useCallback(() => {
    if (isAuthModalOpen) return;
    openAuthModal();
  }, [isAuthModalOpen, openAuthModal]);

  const handleLogout = useCallback(() => {
    signOut(auth)
      .then(() => {
        dispatchOutput({ type: 'CLEAR_PREVIOUS_IMAGES' }); // Clear images on logout
      })
      .catch((error) => {
        console.error('Error logging out: ', error.message);
      });
  }, []);

  // WebSocket States
  const [genConnected, setGenConnected] = useState(false);
  const [trainConnected, setTrainConnected] = useState(false);
  const [genSocket, setGenSocket] = useState(null);
  const [trainSocket, setTrainSocket] = useState(null);

  // Handle Job Queue Updates
  useEffect(() => {
    if (genSocket) {
      const handleQueueUpdate = (updatedQueue) => {
        const jobsToUpdate = updatedQueue.map(({ jobId, queuePosition }) => ({
          jobId,
          queuePosition,
        }));
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: jobsToUpdate });
      };

      genSocket.on('queue_update', handleQueueUpdate);

      return () => {
        genSocket.off('queue_update', handleQueueUpdate);
      };
    }
  }, [genSocket]);

  // Handle Job Updates for Generation Jobs
  useEffect(() => {
    if (genSocket) {
      const handleJobUpdate = (data) => {
        const {
          jobId,
          status,
          image_url,
          queuePosition,
          jobType,
          jobName,
        } = data;

        console.log('Received job_update for jobId:', jobId, data);

        const existingJob = outputStateRef.current.jobs.find(
          (job) => job.jobId === jobId
        );
        if (!existingJob) {
          console.warn(`Received job_update for unknown jobId: ${jobId}. Ignoring.`);
          return; // Ignore job updates for unknown jobs
        }

        // Prepare updated data
        const updatedData = {
          ...data,
          imageUrl: image_url || null,
        };

        // Dispatch the update action
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: [updatedData] });

        // Handle UI feedback based on status
        if (status === 'processing') {
          const name = jobName || existingJob.jobName || 'Unnamed Job';
          console.log(`Job "${name}" is processing...`);
        }

        if (status === 'completed' && image_url) {
          handleNewImage(image_url);
        }

        if (status === 'failed') {
          const name = jobName || existingJob.jobName || 'Unnamed Job';
          toast.error(`Job "${name}" failed.`);
        }

        if (status === 'completed' || status === 'failed') {
          setCurrentJobId(null);
        }
      };

      genSocket.on('job_update', handleJobUpdate);

      return () => {
        genSocket.off('job_update', handleJobUpdate);
      };
    }
  }, [genSocket, handleNewImage]);

  // Handle Job Updates for Training Jobs
  useEffect(() => {
    if (trainSocket) {
      const handleJobUpdate = (data) => {
        const {
          jobId,
          status,
          image_url,
          queuePosition,
          jobType,
          jobName,
        } = data;

        console.log('Received job_update for jobId:', jobId, data);

        const existingJob = outputStateRef.current.jobs.find(
          (job) => job.jobId === jobId
        );
        if (!existingJob) {
          console.warn(`Received job_update for unknown jobId: ${jobId}. Ignoring.`);
          return; // Ignore job updates for unknown jobs
        }

        // Prepare updated data
        const updatedData = {
          ...data,
          imageUrl: image_url || null,
        };

        // Dispatch the update action
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: [updatedData] });

        // Handle UI feedback based on status
        if (status === 'completed' && image_url) {
          if (jobType === 'generation') {
            handleNewImage(image_url);
          } else if (jobType === 'training') {
            // Handle training job completion if needed
          }
        }

        if (status === 'failed') {
          const name = jobName || existingJob.jobName || 'Unnamed Job';
          if (jobType === 'generation') {
            toast.error(`Job "${name}" failed.`);
          } else if (jobType === 'training') {
            toast.error(`Training job "${name}" failed.`);
          }
        }

        if (status === 'completed' || status === 'failed') {
          setCurrentJobId(null);
          setRefreshLoras((prev) => !prev); // Toggle to trigger useEffect in LeftPanel
        }
      };

      trainSocket.on('job_update', handleJobUpdate);

      return () => {
        trainSocket.off('job_update', handleJobUpdate);
      };
    }
  }, [trainSocket, handleNewImage]);

  // Handle Job Queued Events
  useEffect(() => {
    if (genSocket) {
      const handleJobQueued = (data) => {
        const { jobId, jobName, queuePosition, jobType, status } = data;

        console.log('Received job_queued for jobId:', jobId, data);

        dispatchOutput({
          type: 'JOB_QUEUED',
          jobId: jobId,
          jobName: jobName || 'Unnamed Job',
          queuePosition: queuePosition || 0,
          jobType: jobType || 'generation',
          status: status || 'queued',
        });
      };

      genSocket.on('job_queued', handleJobQueued);

      return () => {
        genSocket.off('job_queued', handleJobQueued);
      };
    }
  }, [genSocket]);

  // Handle Job Queued Events for Training (trainSocket)
  useEffect(() => {
    if (trainSocket) {
      const handleJobQueued = (data) => {
        const { jobId, jobName, queuePosition, jobType, status } = data;

        console.log('Received job_queued on trainSocket:', data);

        dispatchOutput({
          type: 'JOB_QUEUED',
          jobId,
          jobName: jobName || 'Unnamed Training Job',
          queuePosition: queuePosition || 0,
          jobType: jobType || 'training',
          status: status || 'queued',
        });
      };

      trainSocket.on('job_queued', handleJobQueued);

      return () => {
        trainSocket.off('job_queued', handleJobQueued);
      };
    }
  }, [trainSocket]);

  // Initialize WebSockets for Job Updates
  useEffect(() => {
    if (!currentUser) return; // Ensure the user is authenticated

    const uid = currentUser.uid;
    const email = currentUser.email;
    const username =
      currentUser.displayName ||
      currentUser.email ||
      currentUser.phoneNumber ||
      'anonymous';

    // Initialize Image Generation WebSocket
    console.log('Connecting to Image Generation WebSocket...');
    const newGenSocket = io('https://api.onlyjpegs.com', {
      withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
    });

    const handleGenConnect = () => {
      console.log('Connected to Image Generation WebSocket.');
      setGenConnected(true);

      // Emit 'authenticate' event with the user uid
      console.log(`Authenticating with uid: ${uid}`);
      newGenSocket.emit('authenticate', { uid });
    };

    const handleGenDisconnect = () => {
      console.log('Disconnected from Image Generation WebSocket.');
      setGenConnected(false);
    };

    const handleGenConnectError = (error) => {
      console.error('Image Generation Connection Error:', error);
    };

    const handleGenReconnect = (attemptNumber) => {
      console.info(
        `Reconnected to Image Generation server after ${attemptNumber} attempts.`
      );
      toast.success('Reconnected to Image Generation WebSocket server.');
    };

    const handleGenReconnectFailed = () => {
      console.error('Failed to reconnect to Image Generation WebSocket server.');
    };

    newGenSocket.on('connect', handleGenConnect);
    newGenSocket.on('disconnect', handleGenDisconnect);
    newGenSocket.on('connect_error', handleGenConnectError);
    newGenSocket.on('reconnect', handleGenReconnect);
    newGenSocket.on('reconnect_failed', handleGenReconnectFailed);

    setGenSocket(newGenSocket);

    // Initialize Training WebSocket
    console.log('Connecting to Training WebSocket...');
    const newTrainSocket = io('https://api.blerst.com', { // Updated to match backend domain
      withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
    });

    const handleTrainConnect = () => {
      console.log('Connected to Training WebSocket.');
      setTrainConnected(true);

      // Emit 'authenticate' event with uid
      newTrainSocket.emit('authenticate', { uid }); // Changed from 'username' to 'uid'
    };

    const handleTrainDisconnect = () => {
      console.log('Disconnected from Training WebSocket.');
      setTrainConnected(false);
    };

    const handleTrainConnectError = (error) => {
      console.error('Training Connection Error:', error);
    };

    const handleTrainReconnect = (attemptNumber) => {
      console.info(
        `Reconnected to Training server after ${attemptNumber} attempts.`
      );
      toast.success('Reconnected to Training WebSocket server.');
    };

    const handleTrainReconnectFailed = () => {
      console.error('Failed to reconnect to Training WebSocket server.');
    };

    newTrainSocket.on('connect', handleTrainConnect);
    newTrainSocket.on('disconnect', handleTrainDisconnect);
    newTrainSocket.on('connect_error', handleTrainConnectError);
    newTrainSocket.on('reconnect', handleTrainReconnect);
    newTrainSocket.on('reconnect_failed', handleTrainReconnectFailed);

    setTrainSocket(newTrainSocket);

    // Cleanup on unmount
    return () => {
      newGenSocket.off('connect', handleGenConnect);
      newGenSocket.off('disconnect', handleGenDisconnect);
      newGenSocket.off('connect_error', handleGenConnectError);
      newGenSocket.off('reconnect', handleGenReconnect);
      newGenSocket.off('reconnect_failed', handleGenReconnectFailed);
      newGenSocket.disconnect();

      newTrainSocket.off('connect', handleTrainConnect);
      newTrainSocket.off('disconnect', handleTrainDisconnect);
      newTrainSocket.off('connect_error', handleTrainConnectError);
      newTrainSocket.off('reconnect', handleTrainReconnect);
      newTrainSocket.off('reconnect_failed', handleTrainReconnectFailed);
      newTrainSocket.disconnect();
    };
  }, [currentUser]);

  // Callable Function to Submit Image Generation Job with Credit Deduction
  const submitImageGenerationJob = httpsCallable(
    functions,
    'submitImageGenerationJob'
  );

  // Handle Single Job Submission
  const handleSingleJobSubmit = useCallback(
    async (promptToUse) => {
      setIsSubmitting(true);

      try {
        if (!currentUser) {
          openAuthModal();
          return;
        }

        const uid = currentUser.uid;

        const result = await submitImageGenerationJob({
          prompt: promptToUse,
          width: inputState.width,
          height: inputState.height,
          lora: inputState.lora === 'None' ? null : inputState.lora,
          instruction: inputState.instruction || null,
          uid: uid,
          email: currentUser.email,
          is_enhance: inputState.isEnhance,
        });

        const response = result.data;

        const jobId = response.jobId;
        if (jobId) {
          console.log(`Submitting job with ID: ${jobId}`);
          const jobName = generateJobName(promptToUse, jobId, mode);
          dispatchOutput({
            type: 'JOB_QUEUED',
            jobId,
            jobName,
            queuePosition: response.queuePosition,
            jobType: 'generation',
            status: 'queued',
          });
          setCurrentJobId(jobId);
        } else {
          toast.error('Failed to retrieve job ID from backend.');
        }
      } catch (error) {
        // Check if the error has a response (Axios error)
        if (error.response) {
          const { status, data } = error.response;

          if (status === 429) {
            // Display a toast notification for 429 errors
            toast.error(data.status || 'Too many jobs in process. Please wait.');
          } else if (data && data.status) {
            // Handle other backend errors
            toast.error(data.status);
          } else {
            // Generic error message
            toast.error('An unexpected error occurred.');
          }
        } else {
          // Handle errors without a response (network errors, etc.)
          toast.error('Network error. Please try again.');
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      inputState,
      dispatchOutput,
      mode,
      currentUser,
      submitImageGenerationJob,
      openAuthModal,
    ]
  );

  // Handle Batch Job Submission
  const handleBatchSubmit = useCallback(async () => {
    setIsSubmitting(true);
    dispatchOutput({ type: 'SUBMIT' });

    try {
      const defaultPrompt = 'Empty Prompt Entered. Generating random image.';

      if (isProUser) {
        const promptsArray = (inputState.prompt || defaultPrompt)
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line !== '');

        for (const prompt of promptsArray) {
          for (let i = 0; i < batchSize; i++) {
            await handleSingleJobSubmit(prompt);
          }
        }
      } else {
        for (let i = 0; i < batchSize; i++) {
          console.log(
            'Submitting standard prompt:',
            inputState.prompt || defaultPrompt
          );
          await handleSingleJobSubmit(inputState.prompt || defaultPrompt);
        }
      }
    } catch (error) {
      console.error('Error submitting job:', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [
    batchSize,
    handleSingleJobSubmit,
    inputState.prompt,
    dispatchOutput,
    isProUser,
  ]);


  // Handle Input Changes
  const handleInputChange = useCallback((field, value) => {
    setInputState((prev) => ({ ...prev, [field]: value }));
  }, []);

  // Generate Job Name
  const generateJobName = useCallback((prompt, jobId, type = 'Job') => {
    const shortenedPrompt = prompt.split(' ').slice(0, 50).join('_');
    return `${shortenedPrompt}`;
  }, []);

  // Toggle Job Panel on Mobile
  const togglePanel = useCallback(() => {
    if (isMobile) {
      setIsPanelCollapsed((prev) => !prev);
    }
  }, [isMobile]);

  // Handle Open/Close Train Modal
  const openTrainModal = useCallback(() => {
    setIsTrainModalOpen(true);
  }, []);

  const closeTrainModal = useCallback(() => {
    setIsTrainModalOpen(false);
  }, []);

  // Handle Pricing Modal
  const isPricingPath = location.pathname === '/pricing';
  const closePricingModal = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  // Handle Payment Success Toast
  useEffect(() => {
    if (location.state && location.state.fromPayment) {
      toast.success(
        'Payment successful! Credits have been added to your account.'
      );
    }
  }, [location]);

  // Render Loading State
  if (authLoading) {
    return (
      <div className="loadingContainer">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="appContainer">
      <Header
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        isAuthModalOpen={isAuthModalOpen}
        genConnected={genConnected}
        trainConnected={trainConnected}
      />
      <Routes>
        <Route
          path="/"
          element={
            <div className="mainContent">
              <NetworkStatus />
              <LeftPanel
                mode={mode}
                setMode={setMode}
                inputState={inputState}
                handleInputChange={handleInputChange}
                handleSubmit={handleBatchSubmit}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                outputState={outputState}
                isSubmitting={isSubmitting}
                isProUser={isProUser}
                refreshLoras={refreshLoras}
                dispatchOutput={dispatchOutput} // Now this will be defined
              />
              {isMobile && (
                <CollapseButton
                  isPanelCollapsed={isPanelCollapsed}
                  togglePanel={togglePanel}
                />
              )}
              <div
                className={`rightPanelContainer ${
                  isPanelCollapsed && isMobile ? 'collapsed' : ''
                }`}
              >
                <RightPanel
                  outputState={outputState}
                  dispatchOutput={dispatchOutput}
                  jobs={outputState.jobs}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  isLoadingMore={isLoadingMore}
                  loadMoreImages={loadMoreImages}
                  hasMoreImages={hasMoreImages}
                />
              </div>
            </div>
          }
        />
        {/* Other routes */}
      </Routes>

      {/* Modals */}
      <Pricing isOpen={isPricingPath} onRequestClose={closePricingModal} />
      <TermsOfService
        isOpen={isTermsOpen}
        onRequestClose={() => setIsTermsOpen(false)}
      />
      <PrivacyPolicy
        isOpen={isPrivacyOpen}
        onRequestClose={() => setIsPrivacyOpen(false)}
      />
      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={closeAuthModal}
      />
      <TrainModal
        isOpen={isTrainModalOpen}
        onRequestClose={closeTrainModal}
        dispatchOutput={dispatchOutput} // Now this will be defined
      />
    </div>
  );
}

export default App;