// src/pages/Pricing.js

import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useAuth } from '../contexts/AuthContext';
import { httpsCallable } from 'firebase/functions'; // Import httpsCallable
import { functions } from '../components/firebaseConfig'; // Ensure functions is imported
import '../components/styles/Pricing.css'; // Ensure this path is correct
import { toast } from 'react-toastify';

const Pricing = ({ isOpen, onRequestClose }) => {
  const { currentUser, isProUser, loading } = useAuth();

  const [loadingPayment, setLoadingPayment] = useState(null); // null or a unique identifier
  const [activeFAQ, setActiveFAQ] = useState(null); // State to track active FAQ

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  // Updated FAQ Data
  const faqs = [
    {
      question: 'How does the free trial work?',
      answer:
        'New users can start with a 200 credits free trial, which gives you access to all Standard features. The free trial can be converted to a paid plan at any time from the pricing page.',
    },
    {
      question: 'What is a credit?',
      answer:
        '1 credit corresponds to 1 image generation at 512x512 resolution. Larger images require more credits; for example, a 1024x1024 image costs 4 credits. Training styles or likeness models costs 80 credits.',
    },
    {
      question: 'What else are credits used for?',
      answer:
        'Credits can be used to generate images or train models on our platform.',
    },
    {
      question: 'How do I purchase credits?',
      answer:
        'You can purchase additional credits directly on this pricing page. Choose the number of credits you wish to buy or enroll in a subscription plan for monthly credits.',
    },
    {
      question: 'What is the difference between subscription credits and one-time purchase credits?',
      answer:
        'Subscription plans provide a set number of credits each month, which reset on a monthly cycle. One-time purchases allow you to buy additional credits that never expire, giving you flexibility to generate images or train models beyond your monthly limit.',
    },
    {
      question: 'Do purchased credits expire?',
      answer:
        'No, one-time purchased credits never expire.',
    },
    {
      question: 'What happens if I run out of credits?',
      answer:
        'If you run out of credits, you will be unable to generate new images or train styles/likeness until you purchase more credits or upgrade your subscription. You can buy additional credits anytime from this page.',
    },
    {
      question: 'What features are included in the free plan?',
      answer:
        'With the free plan, you can generate images using your trial credits and train your own models. Some advanced features are only available to users on the Pro subscription plan.',
    },
    // Optional: Add more FAQs as needed
    {
      question: 'How can I upgrade or downgrade my subscription plan?',
      answer:
        'You can upgrade or downgrade your subscription plan from the pricing page. Upgrades will prorate credits immediately. Downgrades will take effect at the end of your billing cycle.',
    },
    {
      question: 'Are there any discounts for annual subscriptions?',
      answer:
        'Currently, we offer monthly subscription plans. Stay tuned for future updates regarding annual subscription discounts!',
    },
  ];

  // Define your Price IDs consistently with your server's PRICE_MAP
  const PRICE_IDS = {
    oneTime: {
      '100 credits': 'price_1Q51f6FJM2kqxHtJoQ7E8uhV', // 100 credits - $5.00
      '1000 credits': 'price_1Q51dDFJM2kqxHtJ2hWZoqJk', // 1000 credits - $25.00
    },
    subscription: {
      Standard: 'price_1Q9NLDFJM2kqxHtJNBmyf2Ig', // Standard Plan
      Pro: 'price_1Q9NMHFJM2kqxHtJFnFy9Fp5', // Pro Plan
    },
  };

  // Determine the user's current subscription plan
  const getCurrentPlan = () => {
    if (loading) return null; // Don't determine plan if still loading
    if (!currentUser) return 'Free';
    if (isProUser) return 'Pro';
    // Assuming there is a subscription field to determine Standard or Free
    return currentUser.subscription === 'Standard' ? 'Standard' : 'Free';
  };

  const currentPlan = getCurrentPlan();

  // Handle Payment (Subscription or One-Time Purchase)
  const handlePayment = async (type, value, paymentId) => {
    if (loading) {
      toast.warn('Authentication in progress. Please wait...');
      return;
    }

    if (!currentUser) {
      toast.error('Please sign in to proceed with the payment.');
      return;
    } else {
      console.log('Authenticated User:', currentUser);
    }

    try {
      setLoadingPayment(paymentId); // Set loading state for the specific payment action

      // Call the createCheckoutSession callable function
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const response = await createCheckoutSession({ type, value });

      const { sessionUrl } = response.data;

      if (!sessionUrl) {
        throw new Error('No session URL returned from the server.');
      }

      // Redirect to Stripe Checkout
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error creating Checkout Session:', error);
      toast.error('Failed to initiate payment. Please try again.');
    } finally {
      setLoadingPayment(null); // Reset loading state after processing
    }
  };

  if (loading) {
    // Render a loading state within the modal
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Pricing"
        className="pricingModal"
        overlayClassName="pricingModalOverlay"
      >
        <div className="pricingModalHeader">
          <h2>Pricing</h2>
          <button onClick={onRequestClose} className="closeModalButton" aria-label="Close Pricing Modal">
            &times;
          </button>
        </div>
        <div className="loading">Loading...</div>
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Pricing"
      className="pricingModal"
      overlayClassName="pricingModalOverlay"
      closeTimeoutMS={300}
    >
      <div className="pricingModalHeader">
        <h2>Pricing</h2>
        <button onClick={onRequestClose} className="closeModalButton" aria-label="Close Pricing Modal">
          &times;
        </button>
      </div>
      <div className="pricingPlans">
        {/* Free Plan */}
        <div className="plan">
          <h3>Free</h3>
          <p>Start with a 200 credits free trial. After the trial, enjoy 200 credits each month for free, no credit card required.</p>
          <p><strong>200 credits free trial</strong></p>
          <ul>
            <li>200 credits</li>
            <li>Train your own models</li>
          </ul>
          {currentPlan === 'Free' ? (
            <button className="selectPlanButton" disabled>
              Current Plan
            </button>
          ) : null}
        </div>
        {/* Standard Plan */}
        <div className="plan">
          <h3>Standard</h3>
          <p>The introductory package with higher generation limits, early access to more features, and more flexibility.</p>
          <p><strong>$20 / month</strong></p>
          <ul>
            <li>1000 credits per month</li>
            <li>Train your own models</li>
            <li>Early access to new features</li>
          </ul>
          {currentPlan === 'Standard' ? (
            <button className="selectPlanButton" disabled>
              Current Plan
            </button>
          ) : (
            <button
              className="selectPlanButton"
              onClick={() => handlePayment('subscription', 'Standard', 'subscribe_standard')}
              disabled={loadingPayment === 'subscribe_standard'}
            >
              {loadingPayment === 'subscribe_standard' ? 'Processing...' : 'Subscribe to Standard'}
            </button>
          )}
        </div>
        {/* Pro Plan */}
        <div className="plan">
          <h3>Pro</h3>
          <p>The ultimate package with the highest number of credits, special features, and priority support.</p>
          <p><strong>$50 / month</strong></p>
          <ul>
            <li>3000 credits per month</li>
            <li>Train your own models</li>
            <li>Early Access to new features</li>
          </ul>
          {currentPlan === 'Pro' ? (
            <button className="selectPlanButton" disabled>
              Current Plan
            </button>
          ) : (
            <button
              className="selectPlanButton"
              onClick={() => handlePayment('subscription', 'Pro', 'subscribe_pro')}
              disabled={loadingPayment === 'subscribe_pro'}
            >
              {loadingPayment === 'subscribe_pro' ? 'Processing...' : 'Subscribe to Pro'}
            </button>
          )}
        </div>
      </div>
      <div className="creditsPurchase">
        <h3>Out of credits?</h3>
        {/* One-Time Purchase: 100 Credits */}
        <button
          className="buyCreditsButton"
          onClick={() => handlePayment('oneTime', '100 credits', 'purchase_100')}
          disabled={loadingPayment === 'purchase_100'}
        >
          {loadingPayment === 'purchase_100' ? 'Processing...' : 'Purchase 100 credits - $5.00'}
        </button>
        {/* One-Time Purchase: 1000 Credits */}
        <button
          className="buyCreditsButton"
          onClick={() => handlePayment('oneTime', '1000 credits', 'purchase_1000')}
          disabled={loadingPayment === 'purchase_1000'}
        >
          {loadingPayment === 'purchase_1000' ? 'Processing...' : 'Purchase 1000 credits - $25.00'}
        </button>
      </div>

      {/* Updated FAQ Section */}
      <div className="faqs">
        <h3>Frequently Asked Questions</h3>
        {faqs.map((faq, index) => (
          <div key={index} className={`faq ${activeFAQ === index ? 'active' : ''}`}>
            <div
              className="faq-question"
              onClick={() => toggleFAQ(index)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') toggleFAQ(index);
              }}
              aria-expanded={activeFAQ === index}
              aria-controls={`faq-answer-${index}`}
            >
              {faq.question}
            </div>
            {activeFAQ === index && (
              <div className="faq-answer" id={`faq-answer-${index}`}>
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Payment handled via Stripe Checkout */}
    </ReactModal>
  );
};

export default Pricing;