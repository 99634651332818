// src/components/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  useDeviceLanguage,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyA1iCHd37-9wynjQ3PH52R9xCKmdqbFz1w",
  authDomain: "onlyjpegs-9de89.firebaseapp.com",
  projectId: "onlyjpegs-9de89",
  storageBucket: "onlyjpegs-9de89.appspot.com",
  messagingSenderId: "800756676791",
  appId: "1:800756676791:web:91fb5bd93d378345f7b532",
  measurementId: "G-H9L77V7WY7",
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Functions with specified region
const functions = getFunctions(app, 'us-central1'); // Replace 'us-central1' with your functions' region if different

// Connect to Firebase Functions emulator during development (optional)
// Uncomment the following lines if you're using the emulator
// if (window.location.hostname === 'localhost') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

// Initialize Firebase App Check (optional but recommended for enhanced security)
// Replace 'YOUR_RECAPTCHA_SITE_KEY' with your actual reCAPTCHA v3 site key
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('YOUR_RECAPTCHA_SITE_KEY'),
//   isTokenAutoRefreshEnabled: true, // Automatically refresh App Check tokens
// });

// Set authentication persistence to SESSION
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log('Firebase Auth persistence set to SESSION');
  })
  .catch((error) => {
    console.error('Error setting Firebase Auth persistence:', error);
  });

// Use device language for Firebase Authentication
useDeviceLanguage(auth);

// Debugging: Log the initialization of Firebase Functions
console.log('Firebase Functions initialized:', functions);

// Export Firebase services for use in your application
export { app, auth, db, functions };
